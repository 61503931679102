import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';

const NftGallery = ({ userAddress, selectedNfts, onSelectNft, onDeselectNft, onNftsLoaded, selectedContract }) => {
  const [nfts, setNfts] = useState([]);
  const [tokenSymbol, setTokenSymbol] = useState('');

  const contractAddresses = {
    hounds: '0x4aE74974c3c4A403314f8597f471A6DfCe4e51c4',
    pharaoh: '0xfDfE834F89eDbe3B421477748FEaF08F4AA8B5C0',
  };

  const contractABI = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "start",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "count",
          "type": "uint256"
        }
      ],
      "name": "getUserNFTs",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "rewardsPerTokenPerDay",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "RewardToken",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ], []);

  const isSelected = (nftId) => Array.isArray(selectedNfts) ? selectedNfts.includes(nftId) : selectedNfts.has(nftId);

  const toggleSelection = (nftId) => {
    Array.isArray(selectedNfts)
      ? (selectedNfts.includes(nftId) ? onDeselectNft(nftId) : onSelectNft(nftId))
      : (selectedNfts.has(nftId) ? onDeselectNft(nftId) : onSelectNft(nftId));
  };

  useEffect(() => {
    const fetchStakeInfo = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(selectedContract, contractABI, signer);
          const connectedAddress = await signer.getAddress();

          const unstakeNFTResponse = await contract.getUserNFTs(connectedAddress, 0, 10000);
          const unstakeNFT = unstakeNFTResponse.map(id => id.toNumber());
          const nftDetails = await processNFTs(unstakeNFT);

          setNfts(nftDetails);
          onNftsLoaded(nftDetails);
        } catch (error) {
          console.error('Error fetching stake info:', error);
        }
      } else {
        console.log('Please install MetaMask or another Ethereum wallet provider.');
      }
    };

    fetchStakeInfo();
  }, [contractABI, selectedContract]);

  useEffect(() => {
    const fetchTokenSymbol = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(selectedContract, contractABI, provider);
        try {
          const rewardTokenAddress = await contract.RewardToken();
          const tokenContract = new ethers.Contract(rewardTokenAddress, [
            {
              "constant": true,
              "inputs": [],
              "name": "symbol",
              "outputs": [{ "name": "", "type": "string" }],
              "type": "function"
            }
          ], provider);
          const symbol = await tokenContract.symbol();
          setTokenSymbol(symbol);
        } catch (error) {
          console.error('Error fetching token symbol:', error);
          setTokenSymbol('');
        }
      }
    };

    fetchTokenSymbol();
  }, [selectedContract, contractABI]);

  const processNFTs = async (nftIds) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(selectedContract, contractABI, signer);

    const nftDetailsPromises = nftIds.map(async (id) => {
      const rewardsPerDay = await contract.rewardsPerTokenPerDay(id);
      const rewardsFormatted = Math.floor(ethers.utils.formatUnits(rewardsPerDay, 18));

      let imagePath;
      try {
        if (selectedContract === '0x881d23079D8162Ec6e6DfB3721F0e74e713a11A0') {
          imagePath = require(`../Assets/NFT/${id.toString()}.jpeg`);
        } else {
          imagePath = require(`../Assets/NFTP/${id.toString()}.jpeg`);
        }
      } catch (error) {
        console.error(`Error loading image for NFT ${id}:`, error);
        imagePath = 'path/to/default/image.jpeg'; // Use a default image if the specific image cannot be loaded
      }

      return {
        id: id.toString(),
        image: imagePath,
        name: `NFT #${id.toString()}`,
        rewards: rewardsFormatted,
      };
    });

    return Promise.all(nftDetailsPromises);
  };

  return (
    <div className="nft-gallery">
      {nfts.map((nft, index) => (
        <div
          key={nft.id || index}
          className={`nft-item ${isSelected(nft.id) ? 'selected' : ''}`}
          onClick={() => toggleSelection(nft.id)}
        >
          <img src={nft.image} alt={`NFT ${nft.id}`} />
          <p className="nft-name">{nft.name}</p>
          <p className="nft-rewards">{nft.rewards} {tokenSymbol}</p>
        </div>
      ))}
    </div>
  );
}

export default NftGallery;
