import React, { useState } from 'react';
import './NFTSelectionModalBridge.css';
import NftGalleryBridge from './NFTGalleryBridge';

const NFTSelectionModalBridge = ({ userAddress, selectedNfts, onSelectNft, onDeselectNft, onClose, onDeselectAll, onSave, nftAddress, rpcUrl }) => {
  const [nfts, setNfts] = useState([]);

  const handleSelectAll = () => {
    console.log('Select All Clicked');
    const remainingNfts = nfts.filter(nft => !selectedNfts.some(selected => selected.id === nft.id)).slice(0, 50 - selectedNfts.length);
    if (remainingNfts.length > 0) {
      remainingNfts.forEach(nft => onSelectNft(nft));
    }
  };

  return (
    <div className="nft-selection-modal">
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-button">X</button>
          <div className="selection-controls">
            <button onClick={handleSelectAll}>Select all</button>
            <button onClick={onDeselectAll}>Deselect all</button>
            <button onClick={onSave} className="save-button">Save</button>
          </div>
        </div>
        <NftGalleryBridge
          userAddress={userAddress}
          selectedNfts={selectedNfts}
          onSelectNft={onSelectNft}
          onDeselectNft={onDeselectNft}
          nftAddress={nftAddress}
          rpcUrl={rpcUrl}
          nfts={nfts}
          setNfts={setNfts}
        />
      </div>
    </div>
  );
};

export default NFTSelectionModalBridge;
