import React, { useState, useEffect } from 'react';
import { ethers } from 'ethers';
import './Bridging.css';
import NFTSelectionModalBridge from './NFTSelectionModalBridge';
import axios from 'axios';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

const contractABI = [
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      }
    ],
    "name": "isApprovedForAll",
    "outputs": [
      {
        "internalType": "bool",
        "name": "",
        "type": "bool"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "operator",
        "type": "address"
      },
      {
        "internalType": "bool",
        "name": "approved",
        "type": "bool"
      }
    ],
    "name": "setApprovalForAll",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },{
		"inputs": [
			{
				"internalType": "uint256",
				"name": "tokenId",
				"type": "uint256"
			}
		],
		"name": "getLockedTokenOwner",
		"outputs": [
			{
				"internalType": "address",
				"name": "",
				"type": "address"
			}
		],
		"stateMutability": "view",
		"type": "function"
	},  {
    "inputs": [
      {
        "internalType": "uint256[]",
        "name": "tokenIds",
        "type": "uint256[]"
      }
    ],
    "name": "lockTokens",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      }
    ],
    "name": "getLockedTokensByUser",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "user",
        "type": "address"
      },
      {
        "internalType": "uint256[]",
        "name": "tokenIds",
        "type": "uint256[]"
      }
    ],
    "name": "unlockTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
  },{
    "inputs": [],
    "name": "lockFee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

const getAllLockedTokensABI = [
  {
    "inputs": [],
    "name": "getAllLockedTokens",
    "outputs": [
      {
        "internalType": "uint256[]",
        "name": "",
        "type": "uint256[]"
      },
      {
        "internalType": "address[]",
        "name": "",
        "type": "address[]"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];

const Bridging = () => {
  const [walletAddress, setWalletAddress] = useState('');
  const [selectedChain, setSelectedChain] = useState('shibarium');
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [isApproved, setIsApproved] = useState(false);
  const [status, setStatus] = useState('');
  const [txLink, setTxLink] = useState({ lock: '', unlock: '' });
  const [nfts, setNfts] = useState([]); // Add state to store NFTs
  const [lockFee, setLockFee] = useState(null); // State for the lock fee
  const [networkConfirmed, setNetworkConfirmed] = useState(false); 
  const shibariumRpcUrl = process.env.REACT_APP_SHIBARIUM_RPC_URL;
  const baseRpcUrl = process.env.REACT_APP_BASE_RPC_URL;
  const shibariumBridgeContractAddress = process.env.REACT_APP_SHIBARIUM_BRIDGE_CONTRACT;
  const baseBridgeContractAddress = process.env.REACT_APP_BASE_BRIDGE_CONTRACT;
  const shibariumNftAddress = '0x2d44B98a32442Db3d038B7CA9f817e57cF6C7d16';
  const baseNftAddress = '0xfDfE834F89eDbe3B421477748FEaF08F4AA8B5C0';

  const getRpcUrl = () => selectedChain === 'shibarium' ? shibariumRpcUrl : baseRpcUrl;
  const getBridgeContractAddress = () => selectedChain === 'shibarium' ? shibariumBridgeContractAddress : baseBridgeContractAddress;
  const getNftAddress = () => selectedChain === 'shibarium' ? shibariumNftAddress : baseNftAddress;
  const getNftAddressInvert = () => selectedChain === 'shibarium' ? baseNftAddress:shibariumNftAddress;
  useEffect(() => {
    const fetchWalletAddress = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          if (accounts.length > 0) {
            const address = accounts[0];
            console.log('Wallet Address Set:', address);
            setWalletAddress(address);
          }
        } catch (error) {
          console.error('Failed to fetch wallet address:', error);
        }
      } else {
        console.log('Please install MetaMask or another Ethereum wallet provider.');
      }
    };

    fetchWalletAddress();
  }, []);

  const fetchLockFee = async () => {
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const contract = new ethers.Contract(getBridgeContractAddress(), contractABI, provider);
      const fee = await contract.lockFee();
      setLockFee(ethers.utils.formatEther(fee));
      console.log('Lock fee fetched:', fee);
    } catch (error) {
      console.error('Failed to fetch lock fee:', error);
    }
  };

  useEffect(() => {
    const handleAccountsChanged = async (accounts) => {
      if (accounts.length > 0) {
        setWalletAddress(accounts[0]);
        setSelectedNfts([]);
        checkApproval();
      } else {
        setWalletAddress('');
        setSelectedNfts([]);
        setIsApproved(false);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
      }
    };
  }, []);

  const ensureNetwork = async (rpcUrl) => {
    const networkParams = selectedChain === 'shibarium' ? {
      chainId: '0x6d', // Hexadecimal for 109
      chainName: 'Shibarium',
      nativeCurrency: {
        name: 'SHIB',
        symbol: 'SHIB',
        decimals: 18
      },
      rpcUrls: [rpcUrl],
      blockExplorerUrls: ['https://explorer.shibarium.com/']
    } : {
      chainId: '0x2105', // Hexadecimal for 8453
      chainName: 'Base',
      nativeCurrency: {
        name: 'BASE',
        symbol: 'BASE',
        decimals: 18
      },
      rpcUrls: [rpcUrl],
      blockExplorerUrls: ['https://explorer.base.com/']
    };

    try {
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: networkParams.chainId }]
      });
      setNetworkConfirmed(true);
      fetchLockFee(); // Fetch the lock fee after confirming the network
    } catch (switchError) {
      if (switchError.code === 4902) {
        try {
          await window.ethereum.request({
            method: 'wallet_addEthereumChain',
            params: [networkParams],
          });
          setNetworkConfirmed(true);
          fetchLockFee(); // Fetch the lock fee after adding the network
        } catch (addError) {
          console.error('Error adding network:', addError);
          setNetworkConfirmed(false);
        }
      } else {
        console.error('Error switching network:', switchError);
        setNetworkConfirmed(false);
      }
    }
  };

  const checkApproval = async () => {
    if (window.ethereum && walletAddress) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(getNftAddress(), contractABI, signer);
        console.log(`Checking approval status for NFT address: ${getNftAddress()} and Bridge contract: ${getBridgeContractAddress()}`);
        const allowance = await tokenContract.isApprovedForAll(walletAddress, getBridgeContractAddress());
        setIsApproved(allowance);
        console.log('Approval status:', allowance);
      } catch (error) {
        console.error('Error checking approval status:', error);
      }
    }
  };

  useEffect(() => {
    const checkNetworkAndApproval = async () => {
      const networkSwitched = await ensureNetwork(getRpcUrl());
      if (networkSwitched) {
        checkApproval();
      }
    };

    checkNetworkAndApproval();
  }, [walletAddress, selectedChain]);

  const handleApprove = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(getNftAddress(), contractABI, signer);
      const tx = await tokenContract.setApprovalForAll(getBridgeContractAddress(), true);
      await tx.wait();
      setIsApproved(true);
      console.log('NFTs approved');
    }
  };

  const openModal = () => {
    if (!walletAddress) {
      console.log('Wallet address is not set');
      return;
    }
    console.log('Open Modal Button Clicked with walletAddress:', walletAddress);
    setModalOpen(true);
  };

  const closeModal = () => {
    console.log('Close Modal Button Clicked');
    setModalOpen(false);
  };

  const handleSelectNft = (nft) => {
    console.log('NFT Selected:', nft);
    setSelectedNfts((prevSelectedNfts) => [...prevSelectedNfts, nft]);
  };

  const handleDeselectNft = (nftId) => {
    console.log('NFT Deselected:', nftId);
    setSelectedNfts((prevSelectedNfts) => prevSelectedNfts.filter((nft) => nft.id !== nftId));
  };

  const handleDeselectAll = () => {
    console.log('Deselect All Clicked');
    setSelectedNfts([]);
  };

  const handleSelectAll = () => {
    console.log('Select All Clicked');
    const allNfts = nfts.slice(0, 50).map(nft => ({ id: nft.id }));
    setSelectedNfts(allNfts);
  };



  const handleSave = () => {
    console.log('Save Button Clicked');
    closeModal();
  };

  const handleLockTokens = async () => {
    if (selectedNfts.length === 0) {
      setStatus('No NFTs selected. Please select NFTs to bridge.');
      return;
    }
  
    if (window.ethereum && selectedNfts.length > 0) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const bridgingContract = new ethers.Contract(getBridgeContractAddress(), contractABI, signer);
        const tokenIds = selectedNfts.map(nft => nft.id);
        
        const tx = await bridgingContract.lockTokens(tokenIds, {
          value: ethers.utils.parseEther(lockFee) // Sending the lock fee
        });
        setStatus(`Locked ${selectedNfts.length} NFTs.`);
        setTxLink(prevTxLink => ({
          ...prevTxLink,
          lock: `https://${selectedChain === 'shibarium' ? 'www.shibariumscan.io' : 'basescan.org'}/tx/${tx.hash}`
        }));
  
        await axios.post(`${backendUrl}/notify-lock`, {
          user: walletAddress,
          tokenIds,
          chain: selectedChain,
          txHash: tx.hash
        });
  
        setSelectedNfts([]);
      } catch (error) {
        console.error('Error locking tokens:', error);
        setStatus('Error locking tokens');
      }
    } else {
      setStatus('Ethereum provider not found. Please install MetaMask.');
    }
  };
  

  




const unlockTokensOnOtherChain = async (userAddress, tokenIds) => {
    try {
        const rpcUrl = selectedChain === 'shibarium' ? baseRpcUrl : shibariumRpcUrl;
        const targetBridgeContractAddress = selectedChain === 'shibarium' ? baseBridgeContractAddress : shibariumBridgeContractAddress;

        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const wallet = new ethers.Wallet(process.env.REACT_APP_PRIVATE_KEY, provider);
        const bridgeContract = new ethers.Contract(targetBridgeContractAddress, contractABI, wallet);

        console.log('Unlocking Tokens on other chain:', tokenIds);

        const gasLimit = ethers.BigNumber.from("000000");

        const tx = await bridgeContract.unlockTokens(userAddress, tokenIds, { gasLimit });
        await tx.wait();

        console.log('Tokens unlocked successfully on the other chain');
        setStatus(`Unlocked ${tokenIds.length} NFTs on ${selectedChain === 'shibarium' ? 'Base' : 'Shibarium'} chain.`);
        setTxLink(prevTxLink => ({
            ...prevTxLink,
            unlock: `https://${selectedChain === 'shibarium' ? 'basescan.org' : 'www.shibariumscan.io'}/tx/${tx.hash}`
        }));
        handleUnlockSuccess(); 
        // setSelectedNfts([]); // Clear selected NFTs after successful transaction
    } catch (error) {
        console.error('Error unlocking tokens on the other chain:', error);
        setStatus('Error unlocking tokens on the other chain.');
    }
};


  const handleCheckStuckTransactions = async () => {
    try {
      const txHash = prompt('Please enter the transaction hash:');
      if (!txHash) {
        setStatus('Transaction hash is required.');
        return;
      }
  
      setStatus('Checking for stuck transactions...');
      console.log('Checking for stuck transactions with txHash:', txHash);
  
      const apiUrl = selectedChain === 'shibarium'
        ? `https://www.shibariumscan.io/api/v2/transactions/${txHash}`
        : `https://base.blockscout.com/api/v2/transactions/${txHash}`;
  
      const response = await fetch(apiUrl);
      if (!response.ok) {
        setStatus('Failed to fetch transaction details.');
        return;
      }
  
      const data = await response.json();
      console.log('Transaction data:', data);
  
      const tokenIds = await extractTokenIdsFromTransaction(data, walletAddress, getBridgeContractAddress());
  
      if (tokenIds.length === 0) {
        setStatus('No NFTs found in the transaction.');
        console.log('No NFTs found in the transaction.');
        return;
      }
  
      console.log('Token IDs from transaction:', tokenIds);
  
      const targetBridgeContractAddress = selectedChain === 'shibarium' ? baseBridgeContractAddress : shibariumBridgeContractAddress;
  
      const commonLockedTokens = [];
      for (const tokenId of tokenIds) {
        try {
          const owner = await getNftOwnerFromApi(tokenId, getNftAddressInvert(), selectedChain);
          console.log(`Token ID: ${tokenId}, Owner: ${owner}`);
          if (owner.toLowerCase() === targetBridgeContractAddress.toLowerCase()) {
            commonLockedTokens.push(tokenId);
          }
        } catch (error) {
          console.error(`Error fetching owner for Token ID ${tokenId}:`, error);
        }
      }
  
      if (commonLockedTokens.length > 0) {
        setStatus('Stuck transactions found. Unlocking tokens...');
        console.log('Stuck transactions found. Unlocking tokens...');
  
        // Call unlockTokens on the target bridge contract
        await unlockTokensOnOtherChain(walletAddress, commonLockedTokens);
        
        setStatus(`Found ${commonLockedTokens.length} stuck NFTs on ${selectedChain === 'shibarium' ? 'Base' : 'Shibarium'} chain.`);
      } else {
        setStatus('No stuck transactions found.');
        console.log('No stuck transactions found.');
      }
    } catch (error) {
      console.error('Error checking stuck transactions:', error);
      setStatus('Error checking stuck transactions.');
    }
  };
  
  const getNftOwnerFromApi = async (tokenId, nftAddress, selectedChain) => {
    const apiUrl = selectedChain === 'shibarium'
      ? `https://base.blockscout.com/api/v2/tokens/${nftAddress}/instances/${tokenId}`
      : `https://www.shibariumscan.io/api/v2/tokens/${nftAddress}/instances/${tokenId}`;
  
    const response = await fetch(apiUrl);
    if (!response.ok) {
      throw new Error(`Failed to fetch owner for token ID ${tokenId}`);
    }
    const data = await response.json();
    return data.owner.hash;
  };
  
  
  const extractTokenIdsFromTransaction = async (data, walletAddress, contractAddress) => {
    const tokenIds = [];
  
    // Check if decoded_input exists and contains the parameters
    if (data.decoded_input && data.decoded_input.parameters && data.decoded_input.parameters.length > 0) {
      for (const param of data.decoded_input.parameters) {
        if (param.name === 'arg0' && param.type === 'uint256[]') {
          // Extract all token IDs from the parameter value
          tokenIds.push(...param.value);
        }
      }
    } else {
      // Fallback to extracting from token_transfers if decoded_input is not available
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const sourceBridgeContract = new ethers.Contract(contractAddress, contractABI, signer);
  
      for (const transfer of data.token_transfers) {
        console.log('Transfer:', transfer);
        if (transfer.from.hash.toLowerCase() === walletAddress.toLowerCase() && transfer.to.hash.toLowerCase() === contractAddress.toLowerCase()) {
          const tokenId = transfer.total.token_id;
          try {
            const owner = await sourceBridgeContract.getLockedTokenOwner(tokenId);
            console.log(`Token ID: ${tokenId}, Owner: ${owner}`);
            if (owner.toLowerCase() === walletAddress.toLowerCase()) {
              tokenIds.push(tokenId);
            }
          } catch (error) {
            console.error(`Error fetching owner for Token ID ${tokenId}:`, error);
          }
        }
      }
    }
  
    return tokenIds;
  };
  
  
  const handleUnlockSuccess = () => {
    setSelectedNfts([]); // Clear selected NFTs after successful transaction
    // fetchLockedNfts(); // Refresh the list of locked NFTs
  };
  
  const [polling, setPolling] = useState(false);

  // Polling function to check unlock status
  const pollUnlockStatus = () => {
    if (!polling) {
      setPolling(true);
      const interval = setInterval(async () => {
        try {
          const response = await axios.get(`${backendUrl}/unlock-status`, {
            params: { user: walletAddress }
          });
          const data = response.data;
          if (data.status === 'completed') {
            setStatus('Unlocking process completed.');
            clearInterval(interval);
            setPolling(false);
            // Additional UI updates or data refresh can be done here
          }
        } catch (error) {
          console.error('Error polling unlock status:', error);
          clearInterval(interval);
          setPolling(false);
        }
      }, 10000); // Poll every 10 seconds
    }
  };

  const handleCheckStuckTransactions2 = async () => {
    try {
      setStatus('Checking for stuck transactions...');
      console.log('Checking for stuck transactions...');
      
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const sourceBridgeContract = new ethers.Contract(getBridgeContractAddress(), contractABI, signer);
      
      const targetBridgeContractAddress = selectedChain === 'shibarium' ? baseBridgeContractAddress : shibariumBridgeContractAddress;
      const targetRpcUrl = selectedChain === 'shibarium' ? baseRpcUrl : shibariumRpcUrl;
      const targetProvider = new ethers.providers.JsonRpcProvider(targetRpcUrl);
      const targetBridgeContract = new ethers.Contract(targetBridgeContractAddress, getAllLockedTokensABI, targetProvider);

      console.log(`Source Bridge Contract Address: ${getBridgeContractAddress()}`);
      console.log(`Target Bridge Contract Address: ${targetBridgeContractAddress}`);
      console.log(`Wallet Address: ${walletAddress}`);

      const sourceLockedTokens = await sourceBridgeContract.getLockedTokensByUser(walletAddress);
      console.log('Source Locked Tokens:', sourceLockedTokens);

      const [targetLockedTokens, targetLockedAddresses] = await targetBridgeContract.getAllLockedTokens();
      console.log('Target Locked Tokens:', targetLockedTokens);
      console.log('Target Locked Addresses:', targetLockedAddresses);

      const sourceLockedTokensStr = sourceLockedTokens.map(tokenId => tokenId.toString());
      const targetLockedTokensStr = targetLockedTokens.map(tokenId => tokenId.toString());

      console.log('Source Locked Tokens (String):', sourceLockedTokensStr);
      console.log('Target Locked Tokens (String):', targetLockedTokensStr);

      const commonLockedTokens = sourceLockedTokensStr.filter(tokenId => targetLockedTokensStr.includes(tokenId));
      console.log('Common Locked Tokens:', commonLockedTokens);

      if (commonLockedTokens.length > 0) {
        setStatus('Stuck transactions found. Unlocking tokens...');
        console.log('Stuck transactions found. Unlocking tokens...');
        await unlockTokensOnOtherChain(walletAddress, commonLockedTokens.map(tokenId => ethers.BigNumber.from(tokenId)));
        setStatus(`Unlocked ${commonLockedTokens.length} stuck NFTs on ${selectedChain === 'shibarium' ? 'Base' : 'Shibarium'} chain.`);
      } else {
        setStatus('No stuck transactions found.');
        console.log('No stuck transactions found.');
      }
    } catch (error) {
      console.error('Error checking stuck transactions:', error);
      setStatus('Error checking stuck transactions.');
    }
  };

  return (
    <div className="bridging-container">
      <h1 className="title">BRIDGING OF PHARAOH</h1>
      <div className="step-container">
        <div className="step">
          <p className="step-header">Step 1: Select the order of the bridge.</p>
          <div className="chain-selection">
            <label>
              From:
              <select value={selectedChain} onChange={(e) => setSelectedChain(e.target.value)}>
                <option value="shibarium">Shibarium</option>
                <option value="base">Base</option>
              </select>
            </label>
            <span className="arrow">→</span>
            <label>
              To:
              <select value={selectedChain === 'shibarium' ? 'base' : 'shibarium'} disabled>
                <option value={selectedChain === 'shibarium' ? 'base' : 'shibarium'}>
                  {selectedChain === 'shibarium' ? 'Base' : 'Shibarium'}
                </option>
              </select>
            </label>
          </div>
        </div>
        <div className="step">
          <p className="step-header">Step 2: Select the NFT(s) to bridge (Max 50)</p>
          <button className="open-modal-button" onClick={openModal}>
            Select NFTs to Bridge
          </button>
          <p>Selected NFTs: {selectedNfts.length}</p>
        </div>
        <div className="step">
          <p className="step-header">Step 3: Complete the bridging process (Take note that each transaction from Shibarium to Base will incur a 5 Bone admin fee.)</p>
          {!isApproved && (
            <button className="bridging-button" onClick={handleApprove}>Approve NFTs</button>
          )}
          {isApproved && selectedNfts.length > 0 && (
            <button className="bridging-button" onClick={handleLockTokens}>Bridge NFTs</button>
          )}
          {isApproved && selectedNfts.length === 0 && (
            <p className="status-message">Please select NFTs to bridge.</p>
          )}
        </div>
        {/* <button className="bridging-button" onClick={handleCheckStuckTransactions}>Check Stuck Transactions</button> */}
       
      </div>
      {status && (
  <div className="status-section">
    <p className="step-header">TXs:</p>
    {txLink.lock && (
      <div className="view-transaction">
        <a href={txLink.lock} target="_blank" rel="noopener noreferrer">View Bridge (Out) Transaction</a>
      </div>
    )}
  </div>
)}

      {modalOpen && (
        <NFTSelectionModalBridge
          userAddress={walletAddress}
          selectedNfts={selectedNfts}
          onSelectNft={handleSelectNft}
          onDeselectNft={handleDeselectNft}
          onClose={closeModal}
          onDeselectAll={handleDeselectAll}
          onSelectAll={handleSelectAll} // Pass handleSelectAll as a prop
          onSave={handleSave}
          nftAddress={getNftAddress()}
          rpcUrl={getRpcUrl()}
          nfts={nfts} // Pass nfts as a prop
        />
      )}
    </div>
  );
};

export default Bridging;

