import React, { useState } from 'react';
import './NFTSelectionModal.css';
import NftGallery from './NFTGalleryMintPharaoh';

const NFTSelectionModal = ({ userAddress, onClose, selectedContract }) => {
  const [counts, setCounts] = useState({ used: 0, unused: 0 });

  return (
    <div className="nft-selection-modal">
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-button">X</button>
        </div>
        <div className="modal-body">
          <div className="counts">
            <p>Used Hounds: {counts.used} | Unused Hounds: {counts.unused}</p>
          </div>
          <NftGallery
            userAddress={userAddress}
            selectedContract={selectedContract}
            setCounts={setCounts}
          />
        </div>
      </div>
    </div>
  );
};

export default NFTSelectionModal;
