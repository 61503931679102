import React, { useState, useEffect, useRef } from 'react';
import { ethers } from 'ethers';
import { Link } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const optionsRef = useRef(null);
  const mobileMenuRef = useRef(null);
  const [isWalletConnected, setIsWalletConnected] = useState(false);
  const [fullWalletAddress, setFullWalletAddress] = useState('');
  const [walletAddress, setWalletAddress] = useState('');
  const [showOptions, setShowOptions] = useState(false);
  const [ethBalance, setEthBalance] = useState('');
  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [currentChain, setCurrentChain] = useState(''); // No default, set based on detection

  const toggleMobileMenu = () => {
    setShowMobileMenu(prevState => !prevState);
  };

  useEffect(() => {
    const closeMenu = (e) => {
      if (showMobileMenu && (!e.target.closest('.mobile-menu') && !e.target.closest('.hamburger'))) {
        setShowMobileMenu(false);
      }
    };

    document.addEventListener('click', closeMenu);
    return () => document.removeEventListener('click', closeMenu);
  }, [showMobileMenu]);

  useEffect(() => {
    const handleAccountsChanged = (accounts) => {
      if (accounts.length === 0) {
        setIsWalletConnected(false);
        setWalletAddress('');
        setFullWalletAddress('');
        setEthBalance('');
      } else if (accounts[0] !== fullWalletAddress) {
        setIsWalletConnected(true);
        const formattedAddress = `${accounts[0].substring(0, 6)}...${accounts[0].substring(accounts[0].length - 4)}`;
        setWalletAddress(formattedAddress);
        setFullWalletAddress(accounts[0]);
        getEthBalance(accounts[0]);
      }
    };

    const handleChainChanged = async (chainId) => {
      const chainName = getChainName(chainId);
      setCurrentChain(chainName);
      if (fullWalletAddress) {
        await getEthBalance(fullWalletAddress);
      }
    };

    if (window.ethereum) {
      window.ethereum.on('accountsChanged', handleAccountsChanged);
      window.ethereum.on('chainChanged', handleChainChanged);

      // Detect initial chain
      window.ethereum.request({ method: 'eth_chainId' }).then(chainId => {
        const chainName = getChainName(chainId);
        setCurrentChain(chainName);
      });
    }

    return () => {
      if (window.ethereum) {
        window.ethereum.removeListener('accountsChanged', handleAccountsChanged);
        window.ethereum.removeListener('chainChanged', handleChainChanged);
      }
    };
  }, [fullWalletAddress]);

  useEffect(() => {
    const checkWalletConnected = async () => {
      if (window.ethereum) {
        try {
          const accounts = await window.ethereum.request({ method: 'eth_accounts' });
          if (accounts.length > 0) {
            setIsWalletConnected(true);
            const formattedAddress = `${accounts[0].substring(0, 6)}...${accounts[0].substring(accounts[0].length - 4)}`;
            setWalletAddress(formattedAddress);
            setFullWalletAddress(accounts[0]);
            await getEthBalance(accounts[0]);
          }
        } catch (error) {
          console.error('Error checking for connected wallet:', error);
        }
      }
    };

    checkWalletConnected();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showMobileMenu && mobileMenuRef.current && !mobileMenuRef.current.contains(event.target) && !event.target.matches('.hamburger')) {
        setShowMobileMenu(false);
      }
      if (showOptions && optionsRef.current && !optionsRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [showMobileMenu, showOptions]);

  useEffect(() => {
    if (fullWalletAddress) {
      getEthBalance(fullWalletAddress);
    }
  }, [currentChain, fullWalletAddress]);

  const handleWalletButtonClick = async () => {
    if (!isWalletConnected) {
      try {
        if (window.ethereum) {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          setIsWalletConnected(true);
          const formattedAddress = `${accounts[0].substring(0, 6)}...${accounts[0].substring(accounts[0].length - 4)}`;
          setWalletAddress(formattedAddress);
          setFullWalletAddress(accounts[0]);
          await getEthBalance(accounts[0]);
          setShowOptions(true); // Show options immediately after connecting
        } else {
          alert('MetaMask is not installed. Please consider installing it.');
        }
      } catch (error) {
        console.error('Error connecting to MetaMask:', error);
      }
    } else {
      setShowOptions(prev => !prev);
    }
  };

  const getChainName = (chainId) => {
    const chainMap = {
      '0x6d': 'shibarium',
      '0x2105': 'base',
    };
    return chainMap[chainId] || 'unknown';
  };

  const getEthBalance = async (address) => {
    if (window.ethereum && address) {
      const rpcUrl = currentChain === 'shibarium' ? 'https://rpc.shibrpc.com' : 'https://magical-solitary-snow.base-mainnet.quiknode.pro/cd9baabea6560ba7ee62f728571ba0128b773b9b/';
      const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
      try {
        const balanceWei = await provider.getBalance(address);
        const balanceInEth = ethers.utils.formatEther(balanceWei);
        setEthBalance(parseFloat(balanceInEth).toFixed(2));
      } catch (error) {
        console.error('Error fetching balance:', error);
        setEthBalance('Error');
      }
    }
  };

  const handleLogout = () => {
    setIsWalletConnected(false);
    setWalletAddress('');
    setFullWalletAddress('');
    setEthBalance('');
    setShowOptions(false);
  };

  return (
    <nav>
      <div className="navbar-container">
        <div className="top-row">
          <div className="hamburger" onClick={toggleMobileMenu}>
            &#9776;
          </div>
          <Link to="/" className="logo">
            Pharaoh’s Secret Hounds
          </Link>
        </div>
        <div className="bottom-row">
          <div className="wallet-section">
            {isWalletConnected && currentChain !== 'unknown' && (
              <div className="eth-balance-container">
                <span className="eth-balance">{ethBalance} {currentChain === 'shibarium' ? 'BONE' : 'ETH'}</span>
                <span className="chain-indicator"> [{currentChain.toUpperCase()}]</span>
              </div>
            )}
            <button className="connect-wallet" onClick={handleWalletButtonClick}>
              {isWalletConnected ? walletAddress : 'Connect Wallet'}
            </button>
            {showOptions && (
              <div ref={optionsRef} className="wallet-options">
                <ul>
                  <li onClick={handleLogout}>Logout</li>
                  <li>
                    <a href={`https://shibariumscan.io/address/${fullWalletAddress}`} target="_blank" rel="noopener noreferrer">
                      Visit Shibariumscan
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>
        </div>
      </div>

      <div ref={mobileMenuRef} className={`mobile-menu ${showMobileMenu ? 'show' : ''}`}>
        <ul>
          <li><Link to="/" onClick={toggleMobileMenu}>Home</Link></li>
          <li><Link to="/mint" onClick={toggleMobileMenu}>Mint Hounds</Link></li>
          <li><Link to="/mintPharaoh" onClick={toggleMobileMenu}>Mint Pharaoh</Link></li>
          <li><Link to="/stake" onClick={toggleMobileMenu}>Stake Hounds</Link></li>
          <li><Link to="/stakePharaoh" onClick={toggleMobileMenu}>Stake Pharaoh</Link></li>
          <li><Link to="/bridging" onClick={toggleMobileMenu}>Bridge Pharaoh</Link></li>
          <li>
            <a href="https://www.shibware.com/" target="_blank" rel="noopener noreferrer" onClick={toggleMobileMenu}>
              ShibWare
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
