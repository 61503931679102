import React, { useState, useMemo, useEffect } from 'react';
import { ethers } from 'ethers';
import './Stake.css'; // Ensure this CSS file includes the styles provided earlier
import NFTSelectionModal from './NFTSelectionModal';
import NFTUnstakeModal from './NFTUnstakeModal';

// Define the staking contract options
const contractOptions = [
  { label: 'Rewards in ShibWare', address: '0x69161AFa70c98f72547EcA24fa2C41bBa3f153FD' },
  // { label: 'Rewards in Skullz', address: '0xe6F5aBf55d41CE1f123799d0A069fDB5ca5E9Bb1' }, // Replace with your second contract address
];

const BASE_CHAIN_ID = '0x2105';
const nftAddress = `0xfDfE834F89eDbe3B421477748FEaF08F4AA8B5C0`;

const StakeP = () => {
  // State hooks for dynamic data (replace with real data fetching logic)
  const [isApproved, setIsApproved] = useState(false);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [rewardsPerDay, setRewardsPerDay] = useState(0);
  const [walkBalance, setAvailableRewards] = useState(0);
  const [unStaked, setUnStaked] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [rewardsPerTokenPerDay, setrewardsPerTokenPerDay] = useState(0);
  const [nftBalance, setNftBalance] = useState(0);  // Initialize the balance to 0
  const [totalRewards, setTotalRewards] = useState(0); // Initialize to 0 or any default value
  const [showNftModal, setShowNftModal] = useState(false);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [stakedNfts, setStakedNfts] = useState([]);
  const [popup, setPopup] = useState({ visible: false, message: '', txLink: '' });
  const [allNfts, setAllNfts] = useState([]); // To store all fetched NFTs
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [selectedContract, setSelectedContract] = useState(contractOptions[0].address);
  const [isChainCorrect, setIsChainCorrect] = useState(false);
  const [isChainCheckComplete, setIsChainCheckComplete] = useState(false);

  // ABI for interacting with the contract
  const contractABI = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "",
          "type": "address"
        }
      ],
      "name": "stakers",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "timeOfLastUpdate",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "unclaimedRewards",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "rewardsPerDay",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "items",
          "type": "uint256[]"
        }
      ],
      "name": "getRewardsPerDayMultiple",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "address",
          "name": "_user",
          "type": "address"
        }
      ],
      "name": "userStakeInfo",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "stakedTokenIds",
          "type": "uint256[]"
        },
        {
          "internalType": "uint256",
          "name": "userAvailableRewards",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "rewardsPerDay",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "timeOfLastUpdate",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "balance",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "name": "rewardsPerTokenPerDay",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        }
      ],
      "name": "isApprovedForAll",
      "outputs": [
        {
          "internalType": "bool",
          "name": "",
          "type": "bool"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "address",
          "name": "operator",
          "type": "address"
        },
        {
          "internalType": "bool",
          "name": "approved",
          "type": "bool"
        }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokenIds",
          "type": "uint256[]"
        }
      ],
      "name": "stake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "uint256[]",
          "name": "_tokenIds",
          "type": "uint256[]"
        }
      ],
      "name": "unstake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "inputs": [],
      "name": "claimRewards",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    }, {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "start",
          "type": "uint256"
        },
        {
          "internalType": "uint256",
          "name": "count",
          "type": "uint256"
        }
      ],
      "name": "getUserNFTs",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }, {
      "inputs": [],
      "name": "RewardToken",
      "outputs": [
        {
          "internalType": "contract IERC20",
          "name": "",
          "type": "address"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  useEffect(() => {
    const checkChain = async () => {
      if (window.ethereum) {
        try {
          const chainId = await window.ethereum.request({ method: 'eth_chainId' });
          if (chainId !== BASE_CHAIN_ID) {
            await window.ethereum.request({
              method: 'wallet_switchEthereumChain',
              params: [{ chainId: BASE_CHAIN_ID }],
            });
            setIsChainCorrect(true);
          } else {
            setIsChainCorrect(true);
          }
        } catch (error) {
          console.error('Error switching to Base chain:', error);
          alert('Please switch to the Base chain to continue.');
        }
      } else {
        console.error('MetaMask is not installed.');
      }
      setIsChainCheckComplete(true);
    };

    checkChain();
  }, []);

  useEffect(() => {
    if (!isChainCheckComplete || !isChainCorrect) return;

    const fetchUserAddress = async () => {
      if (window.ethereum) {
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        setUserAddress(accounts[0]);
      }
    };
    fetchUserAddress();
  }, [isChainCheckComplete, isChainCorrect]);

  useEffect(() => {
    if (!isChainCheckComplete || !isChainCorrect) return;

    const fetchStakeInfo = async () => {
      if (!window.ethereum) {
        console.log('Please install MetaMask or another Ethereum wallet provider.');
        return;
      }
      try {
        await window.ethereum.request({ method: 'eth_requestAccounts' });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const contract = new ethers.Contract(selectedContract, contractABI, signer);
        const connectedAddress = await signer.getAddress();

        // Fetch NFT IDs staked by the user
        const unstakeNFTResponse = await contract.getUserNFTs(connectedAddress, 0, 10000);
        const unstakeNFT = unstakeNFTResponse.map(id => id.toNumber());

        // Initialize totalRewards to zero
        let totalRewards = 0;

        if (unstakeNFT.length > 0) {
          // Fetch rewards for these NFT IDs
          const rewardsResponse = await contract.getRewardsPerDayMultiple(unstakeNFT);
          const rewards = rewardsResponse.map(reward => parseFloat(ethers.utils.formatUnits(reward, 18))); // Convert BigNumber to readable format
          // Calculate the total rewards
          totalRewards = rewards.reduce((total, current) => total + current, 0);
        }

        console.log(`Total rewards per day: ${totalRewards}`);
        setTotalRewards(totalRewards); // Update state with calculated or default zero value
      } catch (error) {
        console.error('Error fetching stake info:', error);
        setTotalRewards(0); // Ensure zero is set on error
      }
    };
    fetchStakeInfo();
  }, [contractABI, selectedContract, isChainCheckComplete, isChainCorrect]); // Ensure dependencies are correctly listed

  useEffect(() => {
    if (!isChainCheckComplete || !isChainCorrect) return;

    const checkApproval = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const signer = provider.getSigner();
        const tokenContract = new ethers.Contract(nftAddress, contractABI, signer);
        const userAddress = await signer.getAddress();
        const allowance = await tokenContract.isApprovedForAll(userAddress, selectedContract);
        setIsApproved(allowance); // Set true if allowance is greater than 0
        console.log(allowance);
      }
    };
    checkApproval();
  }, [userAddress, selectedContract, isChainCheckComplete, isChainCorrect]); // Trigger the checkApproval function whenever userAddress or selectedContract changes

  const handleApprove = async () => {
    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(nftAddress, contractABI, signer);
      const tx = await tokenContract.setApprovalForAll(selectedContract, true);
      await tx.wait();
      setIsApproved(true);
    }
  };

  useEffect(() => {
    const fetchTokenSymbol = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(selectedContract, contractABI, provider);
        try {
          // First fetch the address of the reward token
          const rewardTokenAddress = await contract.RewardToken();
          const tokenContract = new ethers.Contract(rewardTokenAddress, [
            // ABI for fetching the token symbol
            {
              "constant": true,
              "inputs": [],
              "name": "symbol",
              "outputs": [{ "name": "", "type": "string" }],
              "type": "function"
            }
          ], provider);
          const symbol = await tokenContract.symbol();
          setTokenSymbol(symbol);
        } catch (error) {
          console.error('Error fetching token symbol:', error);
          setTokenSymbol(''); // Set symbol to empty if there is an error
        }
      }
    };
    fetchTokenSymbol();
  }, [selectedContract, contractABI]);

  useEffect(() => {
    const fetchStakeInfo = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(selectedContract, contractABI, signer);
          const connectedAddress = await signer.getAddress();

          const rewardsPerTokenPerDay = await contract.rewardsPerTokenPerDay(1);
          const formattedrewardsPerTokenPerDay = ethers.utils.formatUnits(rewardsPerTokenPerDay, 18);
          // Fetch userStakeInfo
          const stakeInfo = await contract.userStakeInfo(connectedAddress);
          const formattedRewardsPerDay = ethers.utils.formatUnits(stakeInfo.rewardsPerDay, 18);
          const formattedAvailableRewards = ethers.utils.formatUnits(stakeInfo.userAvailableRewards, 18);

          // Fetch balanceOf
          const balance = await contract.balanceOf(connectedAddress);
          const formattedBalance = ethers.utils.formatUnits(balance, 0);

          // Update state
          setRewardsPerDay(formattedRewardsPerDay);
          setStakedAmount(formattedBalance);
          setAvailableRewards(formattedAvailableRewards);
          setrewardsPerTokenPerDay(formattedrewardsPerTokenPerDay);
        } catch (error) {
          console.error('Error fetching stake info:', error);
        }
      } else {
        console.log('Please install MetaMask or another Ethereum wallet provider.');
      }
    };
    const interval = setInterval(fetchStakeInfo, 1000); // Fetch data every 1 second
    return () => clearInterval(interval); // Cleanup function to clear the interval when component unmounts
  }, [contractABI, selectedContract]);

  useEffect(() => {
    const fetchContractTokenBalance = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const connectedAddress = await signer.getAddress();
          
          const erc20ABI = [
            "function balanceOf(address owner) view returns (uint256)"
          ];
          
          const tokenContractAddress = nftAddress;
          const contractToCheck = selectedContract;
  
          const tokenContract = new ethers.Contract(tokenContractAddress, erc20ABI, provider);
          const balance = await tokenContract.balanceOf(contractToCheck);
          const formattedBalance = ethers.utils.formatUnits(balance, 0);
  
          const balanceWallet = await tokenContract.balanceOf(connectedAddress);
          const formattedBalanceWallet = ethers.utils.formatUnits(balanceWallet, 0);
  
          setUnStaked(formattedBalanceWallet);
          setTotalStaked(formattedBalance);
  
        } catch (error) {
          console.error('Error checking contract token balance:', error);
        }
      } else {
        console.log('Please install MetaMask or another Ethereum wallet provider.');
      }
    };
    const intervalId = setInterval(fetchContractTokenBalance, 1000);
    return () => clearInterval(intervalId); // Cleanup function to clear the interval when the component unmounts
  }, [selectedContract]);

  const handleUnStakeNFTs = async () => {
    if (!window.ethereum) {
      alert("Please install MetaMask to use this feature.");
      return;
    }
    
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []); // Request access if needed
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(selectedContract, contractABI, signer);
      
      // Assuming selectedNfts is an array of selected NFT ids
      const transaction = await stakingContract.unstake(selectedNfts);
      await transaction.wait();
  
      // Clear selected NFTs and close modal here if needed
      setSelectedNfts([]);
      setShowUnstakeModal(false);
      showPopup('NFTs unstaked successfully! ', transaction.hash);
     
    } catch (error) {
      console.error("Unstaking failed:", error);
      alert("Unstaking failed. See console for details.");
    }
  };

  const showPopup = (message, txHash = '') => {
    const txLink = txHash ? `https://www.basescan.io/tx/${txHash}` : '';
    setPopup({visible: true, message, txLink});
  
    // Hide popup after 10 seconds
    setTimeout(() => {
      setPopup({visible: false, message: '', txLink: ''});
    }, 10000);
  };

  const handleStakeNFTs = async () => {
    if (!window.ethereum) {
      alert("Please install MetaMask to use this feature.");
      return;
    }
    
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []); // Request access if needed
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(selectedContract, contractABI, signer);
      
      // Assuming selectedNfts is an array of selected NFT ids
      const transaction = await stakingContract.stake(selectedNfts);
      await transaction.wait();
  
      // Clear selected NFTs and close modal here if needed
      setSelectedNfts([]);
      setShowNftModal(false);
      showPopup('NFTs staked successfully! ', transaction.hash);
    } catch (error) {
      console.error("Staking failed:", error);
      alert("Staking failed. See console for details.");
    }
  };

  const handleClaimAll = async () => {
    if (!window.ethereum) {
      alert("Please install MetaMask to use this feature.");
      return;
    }
    
    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []); // Request access if needed
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(selectedContract, contractABI, signer);
      
      const transaction = await stakingContract.claimRewards();
      await transaction.wait();
  
      showPopup('Claim is successful! ', transaction.hash);
    } catch (error) {
      console.error("Claiming of rewards failed:", error);
      alert("Claiming of rewards failed. See console for details.");
    }
  };

  const handleOpenUnstakeModal = async () => {
    setShowUnstakeModal(true);
  };

  const togglePopup = (visible, message = '', txLink = '') => {
    setPopup({ visible, message, txLink });
  };

  const handleOpenNftModal = () => {
    setShowNftModal(true);
  };

  const handleCloseNftModal = () => {
    setSelectedNfts([]);
    setShowNftModal(false);
    setShowUnstakeModal(false);
  };

  const handleSelectNft = (nftId) => {
    setSelectedNfts(prev => [...prev, nftId]);
  };

  const handleDeselectNft = (nftId) => {
    setSelectedNfts(prev => prev.filter(id => id !== nftId));
  };

  const onSelect100 = () => {
    setSelectedNfts(allNfts.slice(0, 100).map(nft => nft.id));
  };

  const handleNftsLoaded = (nfts) => {
    setAllNfts(nfts);
  };

  const getNFTBalance = async (walletAddress) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send('eth_requestAccounts', []); // Prompt user to connect their wallet
    const signer = provider.getSigner(); // You may not need a signer just to read data
  
    const contractAddress = nftAddress;
    const contractABI = [
      {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "", "type": "uint256" }],
        "type": "function"
      },
    ];
  
    const nftContract = new ethers.Contract(contractAddress, contractABI, provider);
  
    try {
      const balance = await nftContract.balanceOf(walletAddress);
      setNftBalance(balance.toString());
      console.log(`Balance: ${balance.toString()}`); // Outputs the number of NFTs
      return balance;
    } catch (error) {
      console.error('Error fetching NFT balance:', error);
      setNftBalance(0);
    }
  };

  getNFTBalance(selectedContract);

  const handleDeselectAll = () => {
    setSelectedNfts([]);
  };

  const handleContractChange = (event) => {
    setSelectedContract(event.target.value);
  };

  return (
    <div className="staking-container">
      <div className="staking-stats-header">[BASE CHAIN] MY STAKING STATS (PHARAOH)</div>
      <div className="status-indicators">
        <div className="indicator">
          <img src="/Icon1.png" alt="Staked" />
          <span><b>STAKED:</b> {stakedAmount}</span>
        </div>
        <div className="indicator">
          <img src="/Icon2.png" alt="Rewards" />
          <span><b>RATE:</b> {rewardsPerDay} {tokenSymbol} / Day </span>
        </div>
        <div className="indicator">
          <img src="/Icon3.png" alt="Balance" />
          <span><b>REWARD:</b> {Number(walkBalance).toFixed(2)} {tokenSymbol} </span>
        </div>
        <button className="indicator claim-all" onClick={handleClaimAll}>
          <img src="/Icon4.png" alt="Claim" />
          CLAIM REWARD
        </button>
      </div>

      <div className="cards">
        <div className="card stake">
          <div className="card-content">
            <h2>STAKE</h2>
            <p>Pharaohs Available For Staking: {unStaked} <br />({totalRewards} ${tokenSymbol}/Day)</p>
            <div className="images-with-text">
              <div className="image-and-text">
                <img src="Icon6.png" alt="Description" />
              </div>
            </div>
            {
              !isApproved ? (
                <button onClick={handleApprove}>Approve</button>
              ) : (
                unStaked > 0 ? (
                  <>
                    <button className="large-font-button" onClick={handleOpenNftModal}>Select NFT(s) to Stake</button>
                  </>
                ) : (
                  <p className="error-message">You have no NFT available to stake.</p>
                )
              )
            }
          </div>
        </div>

        <div className="card unstake">
          <div className="card-content">
            <h2>UNSTAKE</h2>
            <p>Pharaoh(s) Staked: {stakedAmount} <br />(Total: {nftBalance} / 700 Staked)</p>
            <div className="images-with-text">
              <div className="image-and-text">
                <img src="Icon5.png" alt="Description" />
              </div>
            </div>
            {
              stakedAmount > 0 ? (
                <>
                  <button className="large-font-button" onClick={handleOpenUnstakeModal}>Select NFT(s) to Unstake</button>
                </>
              ) : (
                <p className="error-message">You have no NFT available to unstake.</p>
              )
            }
          </div>
        </div>
      </div>
      {showNftModal && (
        <NFTSelectionModal
          userAddress={userAddress}
          selectedNfts={selectedNfts}
          onSelectNft={handleSelectNft}
          onDeselectNft={handleDeselectNft}
          onClose={handleCloseNftModal}
          onDeselectAll={handleDeselectAll}
          onStake={handleStakeNFTs}
          onSelect100={onSelect100}
          onNftsLoaded={handleNftsLoaded}
          selectedContract={selectedContract} 
        />
      )}

      {showUnstakeModal && (
        <NFTUnstakeModal
          userAddress={userAddress}
          selectedNfts={selectedNfts}
          onSelectNft={handleSelectNft}
          onDeselectNft={handleDeselectNft}
          onClose={handleCloseNftModal}
          onDeselectAll={handleDeselectAll}
          onStake={handleUnStakeNFTs}
          onSelect100={onSelect100}
          onNftsLoaded={handleNftsLoaded}
          selectedContract={selectedContract} 
        />
      )}

      {popup.visible && (
        <div className="mint-popup">
          <span>{popup.message}</span>
          {popup.txLink && <a href={popup.txLink} target="_blank" rel="noopener noreferrer">View Transaction</a>}
          <button className="mint-popup-close" onClick={() => togglePopup(false)}>&times;</button>
        </div>
      )}
    </div>
  );
};

export default StakeP;
