import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import './NFTGalleryBridge.css';

const NftGalleryBridge = ({ userAddress, selectedNfts, onSelectNft, onDeselectNft, nftAddress, rpcUrl, nfts, setNfts }) => {
  const [warning, setWarning] = useState('');

  const contractABI = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "tokenOfOwnerByIndex",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  const isSelected = (nftId) => {
    return selectedNfts.some(nft => nft.id === nftId);
  };

  const toggleSelection = (nftId) => {
    if (isSelected(nftId)) {
      onDeselectNft(nftId);
      setWarning(''); // Clear the warning message when deselecting
    } else {
      if (selectedNfts.length >= 50) {
        setWarning('You can only select up to 50 NFTs.');
      } else {
        onSelectNft({ id: nftId });
        setWarning(''); // Clear the warning message on successful selection
      }
    }
  };

  useEffect(() => {
    const fetchNFTs = async () => {
      if (!userAddress || !rpcUrl) return;

      try {
        const provider = new ethers.providers.JsonRpcProvider(rpcUrl);
        const contract = new ethers.Contract(nftAddress, contractABI, provider);
        const balance = await contract.balanceOf(userAddress);
        console.log(`Balance of NFTs: ${balance.toString()}`); // Log the balance
        const nftPromises = [];

        for (let i = 0; i < balance; i++) {
          nftPromises.push(contract.tokenOfOwnerByIndex(userAddress, i));
        }

        const nftIds = await Promise.all(nftPromises);
        console.log('NFT IDs:', nftIds); // Log the fetched NFT IDs
        const nftDetails = nftIds.map(id => ({
          id: id.toString(),
          image: require(`../Assets/NFTP/${id.toString()}.jpeg`),
          name: `NFT #${id.toString()}`
        }));

        console.log('NFT Details:', nftDetails); // Log the details of the NFTs
        setNfts(nftDetails.sort((a, b) => a.id - b.id)); // Sort the NFTs by id
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    fetchNFTs();
  }, [userAddress, contractABI, nftAddress, rpcUrl, setNfts]);

  return (
    <div className="nft-gallery-container">
      {warning && <p className="warning-message">{warning}</p>}
      <div className="nft-gallery">
        {nfts.map(nft => (
          <div
            key={nft.id}
            className={`nft-item ${isSelected(nft.id) ? 'selected' : ''}`}
            onClick={() => toggleSelection(nft.id)}
          >
            <img src={nft.image} alt={`NFT ${nft.id}`} />
            <p className="nft-name">{nft.name}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default NftGalleryBridge;
