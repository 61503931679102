import React from 'react';
import './NFTSelectionModal.css';
import NftGallery from './NFTGallery';

const NFTSelectionModal = ({
  userAddress,
  selectedNfts,
  onSelectNft,
  onDeselectNft,
  onClose,
  onDeselectAll,
  onStake,
  onSelect100,
  onNftsLoaded,
  selectedContract,
  
   // Assuming this prop is passed and handled appropriately
}) => {
  return (
    <div className="nft-selection-modal">
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-button">X</button>
          <div className="selection-controls">
            <button onClick={onDeselectAll}>Deselect all</button>
            <button onClick={() => onSelect100()}>Select 100</button> {/* New button */}
            <button onClick={onStake} className="stake-button">Stake {selectedNfts.length}</button>
          </div>
        </div>
        <NftGallery
  userAddress={userAddress}
  selectedNfts={selectedNfts}
  onSelectNft={onSelectNft}
  onDeselectNft={onDeselectNft}
  onNftsLoaded={onNftsLoaded}
  selectedContract={selectedContract}
   // Assuming setAllNfts directly can handle the data structure provided by NftGallery
/>

      </div>
    </div>
  );
};

export default NFTSelectionModal;
