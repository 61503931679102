// NFTUnstakeModal.js
import React, { useState } from 'react';
import './NFTUnstakeModal.css'; // Make sure to style your modal appropriately
import NftGalleryUnstake from './NFTGalleryUnstake';

const NFTUnstakeModal = ({ userAddress, selectedNfts, onSelectNft, onDeselectNft, onClose, onDeselectAll,onStake,  onSelect100, onNftsLoaded,selectedContract, }) => {
  return (
    <div className="nft-selection-modal">
      <div className="modal-content">
        <div className="modal-header">
          <button onClick={onClose} className="close-button">X</button>
          <div className="selection-controls">
            <button onClick={onDeselectAll}>Deselect all</button>
            <button onClick={() => onSelect100()}>Select 100</button> {/* New button */}
            <button onClick={onStake} className="stake-button">Unstake {selectedNfts.length}</button>

          </div>
        </div>
        <NftGalleryUnstake
          userAddress={userAddress}
          selectedNfts={selectedNfts}
          onSelectNft={onSelectNft}
          onDeselectNft={onDeselectNft}
          onNftsLoaded={onNftsLoaded}
          selectedContract={selectedContract}
        />
      </div>
    </div>
  );
};

export default NFTUnstakeModal;
