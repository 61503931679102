import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Mint from './components/Mint';
import Stake from './components/Stake'; // Temporarily commented out
import StakeP from './components/StakeP'; // Temporarily commented out
import Home from './components/Home';
import Bridging from './components/Bridging'; // Import the new component
import MintP from './components/MintP'; // Import the new component
import { ethers } from 'ethers';
import './App.css';
import Navbar from './components/Navbar';
import Footer from './components/Footer';

const App = () => {
  const [walletAddress, setWalletAddress] = useState(null);

  // Function to handle wallet connection
  const connectWallet = async () => {
    try {
      // Check if MetaMask is installed
      if (window.ethereum) {
        // Request account access
        const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
        // Set the wallet address
        setWalletAddress(accounts[0]);
      } else {
        // MetaMask not installed
        alert('MetaMask is not installed. Please install MetaMask to connect your wallet.');
      }
    } catch (error) {
      console.error('Error connecting wallet:', error);
    }
  };

  return (
    <Router>
      <Navbar walletAddress={walletAddress} connectWallet={connectWallet} />
      <Routes>
        <Route path="/mint" element={<Mint />} />
        <Route path="/mintPharaoh" element={<MintP />} />
        <Route path="/stake" element={<Stake />} /> // Temporarily commented out
        <Route path="/stakePharaoh" element={<StakeP />} /> // Temporarily commented out
        <Route path="/bridging" element={<Bridging />} /> {/* New Bridging route */}
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
