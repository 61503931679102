import React, { useState, useEffect,useMemo } from 'react';
import { ethers } from 'ethers';
import axios from 'axios';

const NftGalleryUnstake = ({ userAddress, selectedNfts, onSelectNft, onDeselectNft,onNftsLoaded,selectedContract }) => {
  const [nfts, setNfts] = useState([]);
  const [tokenSymbol, setTokenSymbol] = useState('');
  const contractAddresses = {
    hounds: '0x4aE74974c3c4A403314f8597f471A6DfCe4e51c4',
    pharaoh: '0xfDfE834F89eDbe3B421477748FEaF08F4AA8B5C0',
  };

  const contractABI = useMemo(() => [
    {
      "inputs": [
          {
              "internalType": "address",
              "name": "_user",
              "type": "address"
          }
      ],
      "name": "userStakeInfo",
      "outputs": [
          {
              "internalType": "uint256[]",
              "name": "stakedTokenIds",
              "type": "uint256[]"
          },
          {
              "internalType": "uint256",
              "name": "userAvailableRewards",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "rewardsPerDay",
              "type": "uint256"
          },
          {
              "internalType": "uint256",
              "name": "timeOfLastUpdate",
              "type": "uint256"
          }
      ],
      "stateMutability": "view",
      "type": "function"
  },{
    "inputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "name": "rewardsPerTokenPerDay",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
  },{
    "inputs": [],
    "name": "RewardToken",
    "outputs": [
      {
        "internalType": "contract IERC20",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  ], []);

  const isSelected = (nftId) => {
    // If selectedNfts is an array
    if (Array.isArray(selectedNfts)) {
      return selectedNfts.includes(nftId);
    }
    // If selectedNfts is a Set
    return selectedNfts.has(nftId);
  };

  const toggleSelection = (nftId) => {
    // If selectedNfts is an array
    if (Array.isArray(selectedNfts)) {
      if (selectedNfts.includes(nftId)) {
        onDeselectNft(nftId);
      } else {
        onSelectNft(nftId);
      }
    }
    // If selectedNfts is a Set
    else {
      if (selectedNfts.has(nftId)) {
        onDeselectNft(nftId);
      } else {
        onSelectNft(nftId);
      }
    }
  };

  useEffect(() => {
    const fetchTokenSymbol = async () => {
      if (window.ethereum) {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const contract = new ethers.Contract(selectedContract, contractABI, provider);
        try {
          // First fetch the address of the reward token
          const rewardTokenAddress = await contract.RewardToken();
          const tokenContract = new ethers.Contract(rewardTokenAddress, [
            // ABI for fetching the token symbol
            {
              "constant": true,
              "inputs": [],
              "name": "symbol",
              "outputs": [{"name": "", "type": "string"}],
              "type": "function"
            }
          ], provider);
          const symbol = await tokenContract.symbol();
          setTokenSymbol(symbol);
        } catch (error) {
          console.error('Error fetching token symbol:', error);
          setTokenSymbol(''); // Set symbol to empty if there is an error
        }
      }
    };

    fetchTokenSymbol();
  }, [selectedContract, contractABI]);
  
  useEffect(() => {
    const fetchStakeInfo = async () => {
      if (window.ethereum) {
        try {
          await window.ethereum.request({ method: 'eth_requestAccounts' });
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const contract = new ethers.Contract(selectedContract, contractABI, signer);
          const connectedAddress = await signer.getAddress();
  
          const response = await contract.userStakeInfo(connectedAddress);
          const stakedTokenIds = response.stakedTokenIds.map(id => id.toNumber());
          const nftDetails = await processNFTs(stakedTokenIds);
          setNfts(nftDetails);
          onNftsLoaded(nftDetails);
    
        } catch (error) {
          console.error('Error fetching stake info:', error);
        }
      } else {
        console.log('Please install MetaMask or another Ethereum wallet provider.');
      }
    };
    
    fetchStakeInfo();
  }, [contractABI, selectedContract]);
  

  const processNFTs = async (nftIds) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    const signer = provider.getSigner();
    const contract = new ethers.Contract(selectedContract, contractABI, signer);

    const nftDetailsPromises = nftIds.map(async (id) => {
      const rewardsPerDay = await contract.rewardsPerTokenPerDay(id);
      const rewardsFormatted = Math.floor(ethers.utils.formatUnits(rewardsPerDay, 18));

      let imagePath;
      try {
        if (selectedContract === '0x881d23079D8162Ec6e6DfB3721F0e74e713a11A0') {
          imagePath = require(`../Assets/NFT/${id.toString()}.jpeg`);
        } else {
          imagePath = require(`../Assets/NFTP/${id.toString()}.jpeg`);
        }
      } catch (error) {
        console.error(`Error loading image for NFT ${id}:`, error);
        imagePath = 'path/to/default/image.jpeg'; // Use a default image if the specific image cannot be loaded
      }

      return {
        id: id.toString(),
        image: imagePath,
        name: `NFT #${id.toString()}`,
        rewards: rewardsFormatted,
      };
    });

    return Promise.all(nftDetailsPromises);
  };

  

// useEffect(() => {
//   const fetchNfts = async () => {
//     if (!userAddress) return;

//     // setIsLoading(true);
//     let hasNextPage = true;
//     let nextPageToken = null;
//     let fetchedNfts = [];

//     while (hasNextPage) {
//       try {
//         const params = nextPageToken ? `?unique_token=${nextPageToken}` : '';
//         const url = `https://www.shibariumscan.io/api/v2/tokens/${contractAddress}/instances${params}`;
//         const response = await axios.get(url);
//         const { items, next_page_params } = response.data;

//         const userOwnedNfts = items.filter(item => 
//           item.owner && item.owner.hash.toLowerCase() === userAddress.toLowerCase()
//         );

//         fetchedNfts.push(...userOwnedNfts.map(item => ({
//           id: item.id,
//           image: `https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/shibarium/shibulls/${item.id}.png`,
//           name: item.id,
//         })));

//         // Update for the next iteration
//         hasNextPage = !!next_page_params && !!next_page_params.unique_token;
//         nextPageToken = hasNextPage ? next_page_params.unique_token : null;
//       } catch (error) {
//         console.error('Error fetching NFTs:', error);
//         hasNextPage = false;
//       }
//     }

//     setNfts(fetchedNfts);
//     // setIsLoading(false);
//   };

//   fetchNfts();
// }, [userAddress]);


//   useEffect(() => {
//     const fetchNfts = async () => {
//       if (!userAddress) return;
  
//       let uniqueToken = 10000;
//       let hasNext = true;
//       let fetchedNfts = []; // Using an array to store all NFTs
  
//       while (hasNext && uniqueToken >= 0) {
//         const url = `https://www.shibariumscan.io/api/v2/tokens/${contractAddress}/instances?unique_token=${uniqueToken}`;
  
//         try {
//           const response = await axios.get(url);
//           const { items, next_page_params } = response.data;
  
//           items.forEach(item => {
//             if (item.owner && item.owner.hash.toLowerCase() === userAddress.toLowerCase()) {
//               fetchedNfts.push({
//                 id: item.id,
//                 image: `https://assets-mantra.nyc3.cdn.digitaloceanspaces.com/shibarium/shibulls/${item.id}.png`,
//                 name: item.id,
//               });
//             }
//           });
  
//           // Update uniqueToken for the next iteration
//           if (next_page_params && next_page_params.unique_token !== undefined) {
//             uniqueToken = next_page_params.unique_token;
//           } else {
//             hasNext = false; // No more pages
//           }
//         } catch (error) {
//           console.error('Error fetching NFTs:', error);
//           hasNext = false;
//         }
//       }
  
//       setNfts(fetchedNfts);
//     };
  
//     fetchNfts();
//   }, [userAddress]);
  

return (
  <div className="nft-gallery">
    {nfts.map((nft, index) => (
      <div
        key={nft.id || index}
        className={`nft-item ${isSelected(nft.id) ? 'selected' : ''}`}
        onClick={() => toggleSelection(nft.id)}
      >
        <img src={nft.image} alt={`NFT ${nft.id}`} />
        <p className="nft-name">{nft.name}</p> {/* Assign class for the name */}
        <p className="nft-rewards">{nft.rewards} {tokenSymbol}</p> {/* Assign class for the rewards */}
      </div>
    ))}
  </div>
);


}  

export default NftGalleryUnstake;
