import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
// import './NFTGalleryMintPharaoh.css'; // Ensure this path is correct

const NftGallery = ({ userAddress, nftAddress, setCounts }) => {
  const [nfts, setNfts] = useState([]);
  const [usedHounds, setUsedHounds] = useState([]);
  const mintContract = '0x2d44B98a32442Db3d038B7CA9f817e57cF6C7d16';
  const Hound = '0x4aE74974c3c4A403314f8597f471A6DfCe4e51c4';

  const houndContractABI = useMemo(() => [
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "user",
          "type": "address"
        }
      ],
      "name": "balanceOf",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "owner",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "index",
          "type": "uint256"
        }
      ],
      "name": "tokenOfOwnerByIndex",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  const mintContractABI = useMemo(() => [
    {
      "inputs": [],
      "name": "getUsedHounds",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  useEffect(() => {
    const fetchNFTs = async () => {
      if (!userAddress) {
        console.log('User address is missing');
        return;
      }

      try {
        console.log('Fetching NFTs...');
        const provider = new ethers.providers.JsonRpcProvider('https://rpc.shibrpc.com');
        const contract = new ethers.Contract(Hound, houndContractABI, provider);
        const balance = await contract.balanceOf(userAddress);
        console.log(`Balance of NFTs: ${balance.toString()}`); // Log the balance
        const nftPromises = [];

        for (let i = 0; i < balance; i++) {
          nftPromises.push(contract.tokenOfOwnerByIndex(userAddress, i));
        }

        const nftIds = await Promise.all(nftPromises);
        console.log('NFT IDs:', nftIds); // Log the fetched NFT IDs
        const nftDetails = nftIds.map(id => {
          console.log(`Fetching image for NFT ID ${id.toString()}`);
          return {
            id: id.toString(),
            image: require(`../Assets/NFT/${id.toString()}.jpeg`), // Ensure this path is correct
            name: `NFT #${id.toString()}`
          };
        });

        console.log('NFT Details:', nftDetails); // Log the details of the NFTs
        setNfts(nftDetails); // Store the NFTs without sorting for now
      } catch (error) {
        console.error('Error fetching NFTs:', error);
      }
    };

    fetchNFTs();
  }, [userAddress, Hound, houndContractABI]);

  useEffect(() => {
    const fetchUsedHounds = async () => {
      try {
        console.log('Fetching used Hounds...');
        const provider = new ethers.providers.JsonRpcProvider('https://rpc.shibrpc.com');
        const contract = new ethers.Contract(mintContract, mintContractABI, provider);
        const usedHoundsResponse = await contract.getUsedHounds();
        console.log('Used Hounds Response:', usedHoundsResponse);
        setUsedHounds(usedHoundsResponse.map(id => id.toNumber()));
      } catch (error) {
        console.error('Error fetching used Hounds:', error);
      }
    };

    fetchUsedHounds();
  }, [mintContractABI, mintContract]);

  // Sorting function
  useEffect(() => {
    const sortNFTs = () => {
      setNfts(prevNfts =>
        prevNfts
          .map(nft => ({ ...nft, used: usedHounds.includes(Number(nft.id)) }))
          .sort((a, b) => a.id - b.id)
      );
    };
    sortNFTs();
  }, [usedHounds]);

  useEffect(() => {
    const usedCount = nfts.filter(nft => usedHounds.includes(Number(nft.id))).length;
    const unusedCount = nfts.length - usedCount;
    setCounts({ used: usedCount, unused: unusedCount });
  }, [nfts, usedHounds, setCounts]);

  return (
    <div className="nft-gallery-container">
      <div className="nft-gallery">
        {nfts.map(nft => (
          <div
            key={nft.id}
            className={`nft-item ${usedHounds.includes(Number(nft.id)) ? 'used' : ''}`}
          >
            <img src={nft.image} alt={`NFT ${nft.id}`} />
            <p className="nft-name">{nft.name}</p>
            {usedHounds.includes(Number(nft.id)) && <p className="used-notice">Used</p>}
          </div>
        ))}
      </div>
    </div>
  );
};

export default NftGallery;
