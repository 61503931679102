import React from 'react';
import { Link } from 'react-router-dom';
import './Home.css';

const Home = () => {
  return (
    <div className="home-container">
      <h1 className="title">Welcome to Pharaoh's Palace — Behold the Hounds!</h1>
      <div className="button-groups">
        <Link to="/mint" className="link-style">
          <button className="home-button">Mint Hounds</button>
        </Link>
        <Link to="/stake" className="link-style">
          <button className="home-button">Stake Hounds</button>
        </Link>
        <Link to="/mintPharaoh" className="link-style">
          <button className="home-button">Mint Pharaoh</button>
        </Link>
        <Link to="/stakePharaoh" className="link-style">
          <button className="home-button">Stake Pharaoh</button>
        </Link>
        <Link to="/bridging" className="link-style">
          <button className="home-button">Bridge Pharaoh</button>
        </Link>
        <Link to="https://www.shibware.com/" className="link-style">
          <button className="home-button">ShibWare</button>
        </Link>
      </div>
      <div className="nft-display">
        <img src="/35.png" alt="NFT Sample" />
      </div>
      <div className="project-description">
        <p>
          In the heart of ancient Egypt, the Pharaoh’s prized dogs were legendary protectors. In a bold move to immortalize their prowess, the Pharaoh summoned a genius scientist from the future named Poem, hailing from the innovative tech company ShibWare. Together, they embarked on a daring venture: transforming these majestic dogs into formidable, high-tech robotic guardians. Now, these enhanced warriors, products of ancient valor and cutting-edge technology, stand ready to protect their realm across the ages.
        </p>
      </div>
    </div>
  );
};

export default Home;
