import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import './MintP.css';
import NFTSelectionModal from './NFTSelectionModalMintPharaoh';

const MintP = () => {
  const [totalMinted, setTotalMinted] = useState(0);
  const [cost, setCost] = useState(0);
  const [maxMintAmount, setMaxMintAmount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [numToMint, setNumToMint] = useState(1);
  const [popup, setPopup] = useState({ visible: false, message: '', txLink: '' });
  const [mintType, setMintType] = useState('phase2');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [usedHounds, setUsedHounds] = useState([]);
  const [showUsedHounds, setShowUsedHounds] = useState(false);
  const [correctChain, setCorrectChain] = useState(false);

  const contractAddress = "0x2d44B98a32442Db3d038B7CA9f817e57cF6C7d16";
  const shibariumChainId = '0x6d'; // Shibarium chain ID

  const contractABI = useMemo(() => [
    {
      "inputs": [],
      "name": "cost",
      "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "_maxSupply",
      "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "_maxBatchSize",
      "outputs": [{ "internalType": "uint16", "name": "", "type": "uint16" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "batchSize", "type": "uint256" }],
      "name": "publicMintToSender",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "numTokens", "type": "uint256" }],
      "name": "mintGated",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "numTokens", "type": "uint256" }],
      "name": "mint",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "getUsedHounds",
      "outputs": [{ "internalType": "uint256[]", "name": "", "type": "uint256[]" }],
      "stateMutability": "view",
      "type": "function"
    }
  ], []);

  const checkAndSwitchChain = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      alert("No crypto wallet found. Please install MetaMask.");
      return false;
    }

    try {
      const currentChainId = await ethereum.request({ method: 'eth_chainId' });
      if (currentChainId !== shibariumChainId) {
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: shibariumChainId }],
          });
          setCorrectChain(true);
          return true;
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: shibariumChainId,
                  chainName: "Shibarium Mainnet",
                  nativeCurrency: {
                    name: "SHIB",
                    symbol: "SHIB",
                    decimals: 18,
                  },
                  rpcUrls: ["https://rpc.shibrpc.com"],
                  blockExplorerUrls: ["https://shibariumscan.io"],
                }],
              });
              setCorrectChain(true);
              return true;
            } catch (addError) {
              console.error("Failed to add Shibarium chain to MetaMask:", addError);
              alert("Failed to add Shibarium chain. Please add it manually.");
              return false;
            }
          } else {
            console.error("Error switching to Shibarium chain:", switchError);
            alert("Please switch to the Shibarium chain to continue.");
            return false;
          }
        }
      }
      setCorrectChain(true);
      return true;
    } catch (error) {
      console.error("Error checking chain ID:", error);
      return false;
    }
  };

  useEffect(() => {
    const checkChain = async () => {
      const isOnShibarium = await checkAndSwitchChain();
      if (!isOnShibarium) {
        setCorrectChain(false);
      }
    };

    checkChain();
  }, []);

  useEffect(() => {
    if (correctChain) {
      const fetchData = async () => {
        const provider = new ethers.providers.JsonRpcProvider('https://rpc.shibrpc.com');
        const contract = new ethers.Contract(contractAddress, contractABI, provider);

        try {
          const [costBNB, maxSupply, maxMint, totalMinted, usedHoundsResponse] = await Promise.all([
            contract.cost(),
            contract._maxSupply(),
            contract._maxBatchSize(),
            contract.totalSupply(),
            contract.getUsedHounds(),
          ]);

          setCost(ethers.utils.formatEther(costBNB));
          setMaxMintAmount(maxMint.toNumber ? maxMint.toNumber() : parseInt(maxMint));
          setTotalSupply(maxSupply.toNumber ? maxSupply.toNumber() : parseInt(maxSupply));
          setTotalMinted(totalMinted.toNumber ? totalMinted.toNumber() : parseInt(totalMinted));

          const sortedUsedHounds = usedHoundsResponse.map(id => id.toNumber()).sort((a, b) => a - b);
          setUsedHounds(sortedUsedHounds);
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      };

      fetchData();
      const interval = setInterval(fetchData, 1000);
      return () => clearInterval(interval);
    }
  }, [contractABI, contractAddress, correctChain]);

  const showPopup = (message, txHash = '') => {
    const txLink = txHash ? `https://www.shibariumscan.io/tx/${txHash}` : '';
    setPopup({ visible: true, message, txLink });
    setTimeout(() => {
      setPopup({ visible: false, message: '', txLink: '' });
    }, 10000);
  };

  const closePopup = () => {
    setPopup({ visible: false, message: '', txLink: '' });
  };

  const handleMint = async () => {
    if (!correctChain || !window.ethereum) {
      alert("Please install MetaMask and switch to the Shibarium chain to use this feature.");
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      if (mintType === 'phase1') {
        const transaction = await contract.mintGated(numToMint);
        await transaction.wait();
        showPopup('Mint successful! ', transaction.hash);
      } else if (mintType === 'phase2') {
        const totalCost = parseFloat(cost) * numToMint;
        const totalCostWei = ethers.utils.parseEther(totalCost.toFixed(18));
        const transaction = await contract.mint(numToMint, { value: totalCostWei });
        await transaction.wait();
        showPopup('Mint successful! ', transaction.hash);
      }
    } catch (error) {
      console.error("Minting failed:", error);
      let errorMessage = 'Minting failed.';

      if (error.data && error.data.message) {
        if (error.data.message.includes('Insufficient Hounds tokens to mint')) {
          errorMessage = 'Minting failed. Some of your Hounds tokens have already been used.';
        } else if (error.data.message.includes('Phase 1 minting is not enabled')) {
          errorMessage = 'Minting failed. Phase 1 minting is not enabled.';
        } else if (error.data.message.includes('Exceeding max supply')) {
          errorMessage = 'Minting failed. Exceeding max supply.';
        } else if (error.data.message.includes('Cannot mint zero tokens')) {
          errorMessage = 'Minting failed. Cannot mint zero tokens.';
        } else if (error.data.message.includes('Cannot mint more than 10 tokens per transaction')) {
          errorMessage = 'Minting failed. Cannot mint more than 10 tokens per transaction.';
        }
      }

      showPopup(errorMessage);
    }
  };

  const openModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="mint-container">
      <div className="mint-type-select-container">
        <label htmlFor="mintType">Select Mint Type:</label>
        <select
          id="mintType"
          className="mint-type-select"
          onChange={(e) => setMintType(e.target.value)}
          value={mintType}
        >
          <option value="phase1">Free Mint (25 Hounds)</option>
          <option value="phase2">Standard Mint (Not Ready)</option>
        </select>
      </div>
      <div className="mint-content">
        <div className="mint-title">Mint The Pharaoh</div>
        <img src="/PC.png" alt="NFT Sample" className="mint-image" />
        {mintType === 'phase1' ? (
          <div className="mint-info">
            <button className="view-nfts-button" onClick={openModal}>View Your NFTs</button>
            <p>You are eligible to mint for free if you hold 25 Hounds.</p>
            <p><label>Max supply:</label> {totalSupply}</p>
            <p><label>Total minted:</label> {totalMinted}</p>
          </div>
        ) : (
          <div className="mint-info">
            <p><label>Cost per token:</label> {cost} Bone</p>
            <p><label>Max mint amount:</label> {maxMintAmount}</p>
            <p><label>Total supply:</label> {totalSupply}</p>
            <p><label>Total minted:</label> {totalMinted}</p>
          </div>
        )}
        <div className="mint-action">
          <input
            type="number"
            value={numToMint}
            onChange={(e) => setNumToMint(Number(e.target.value))}
            min="1"
            max={maxMintAmount}
          />
          <button onClick={handleMint}>Mint</button>
        </div>
        {mintType === 'phase1' && (
          <div className="used-hounds-container">
            <div className="used-hounds-list">
              <p>Used Hounds: {usedHounds.join(', ')}</p>
            </div>
          </div>
        )}
      </div>
      {popup.visible && (
        <div className="mint-popup">
          <span>{popup.message}</span>
          {popup.txLink && <a href={popup.txLink} target="_blank" rel="noopener noreferrer">View Transaction</a>}
          <button className="mint-popup-close" onClick={closePopup}>&times;</button>
        </div>
      )}
      {isModalVisible && (
        <NFTSelectionModal
          userAddress={window.ethereum.selectedAddress}
          onClose={closeModal}
          selectedContract={'0x4aE74974c3c4A403314f8597f471A6DfCe4e51c4'}
        />
      )}
    </div>
  );
};

export default MintP;
