// Footer.js
import React from 'react';
import { FaTelegramPlane, FaTwitter } from 'react-icons/fa'; // Make sure to install react-icons
import './Footer.css'; // Your CSS file for styling the footer

const Footer = () => {
  return (
    <footer className="footer-container">
      <div className="social-links">
        <a href="https://t.me/shibwareportal" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTelegramPlane />
        </a>
        <a href="https://twitter.com/ShibWare" target="_blank" rel="noopener noreferrer" className="social-icon">
          <FaTwitter />
        </a>
      </div>
      <p className="copy-info">© 2024 Pharoah's Secret Hounds. All Rights Reserved.</p>
    </footer>
  );
};

export default Footer;
