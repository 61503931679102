import React, { useState, useMemo, useEffect } from 'react';
import { ethers } from 'ethers';
import './Stake.css';
import NFTSelectionModal from './NFTSelectionModal';
import NFTUnstakeModal from './NFTUnstakeModal';

const contractOptions = [
  { label: 'Rewards in ShibWare', address: '0x881d23079D8162Ec6e6DfB3721F0e74e713a11A0' },
  { label: 'Rewards in Skullz', address: '0xe6F5aBf55d41CE1f123799d0A069fDB5ca5E9Bb1' },
];

const nftAddress = '0x4aE74974c3c4A403314f8597f471A6DfCe4e51c4';
const shibariumChainId = '0x6d'; // Shibarium chain ID (109 in decimal)

const Stake = () => {
  const [isApproved, setIsApproved] = useState(false);
  const [stakedAmount, setStakedAmount] = useState(0);
  const [rewardsPerDay, setRewardsPerDay] = useState(0);
  const [walkBalance, setAvailableRewards] = useState(0);
  const [unStaked, setUnStaked] = useState(0);
  const [totalStaked, setTotalStaked] = useState(0);
  const [rewardsPerTokenPerDay, setRewardsPerTokenPerDay] = useState(0);
  const [nftBalance, setNftBalance] = useState(0);
  const [totalRewards, setTotalRewards] = useState(0);
  const [showNftModal, setShowNftModal] = useState(false);
  const [selectedNfts, setSelectedNfts] = useState([]);
  const [userAddress, setUserAddress] = useState('');
  const [showUnstakeModal, setShowUnstakeModal] = useState(false);
  const [stakedNfts, setStakedNfts] = useState([]);
  const [popup, setPopup] = useState({ visible: false, message: '', txLink: '' });
  const [allNfts, setAllNfts] = useState([]);
  const [tokenSymbol, setTokenSymbol] = useState('');
  const [selectedContract, setSelectedContract] = useState(contractOptions[0].address);
  const [correctChain, setCorrectChain] = useState(false);

  const contractABI = useMemo(() => [
    {
      "inputs": [{ "internalType": "address", "name": "", "type": "address" }],
      "name": "stakers",
      "outputs": [
        { "internalType": "uint256", "name": "timeOfLastUpdate", "type": "uint256" },
        { "internalType": "uint256", "name": "unclaimedRewards", "type": "uint256" },
        { "internalType": "uint256", "name": "rewardsPerDay", "type": "uint256" }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256[]", "name": "items", "type": "uint256[]" }],
      "name": "getRewardsPerDayMultiple",
      "outputs": [{ "internalType": "uint256[]", "name": "", "type": "uint256[]" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "address", "name": "_user", "type": "address" }],
      "name": "userStakeInfo",
      "outputs": [
        { "internalType": "uint256[]", "name": "stakedTokenIds", "type": "uint256[]" },
        { "internalType": "uint256", "name": "userAvailableRewards", "type": "uint256" },
        { "internalType": "uint256", "name": "rewardsPerDay", "type": "uint256" },
        { "internalType": "uint256", "name": "timeOfLastUpdate", "type": "uint256" }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "address", "name": "owner", "type": "address" }],
      "name": "balanceOf",
      "outputs": [{ "internalType": "uint256", "name": "balance", "type": "uint256" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "name": "rewardsPerTokenPerDay",
      "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "address", "name": "owner", "type": "address" },
        { "internalType": "address", "name": "operator", "type": "address" }
      ],
      "name": "isApprovedForAll",
      "outputs": [{ "internalType": "bool", "name": "", "type": "bool" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "address", "name": "operator", "type": "address" },
        { "internalType": "bool", "name": "approved", "type": "bool" }
      ],
      "name": "setApprovalForAll",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256[]", "name": "_tokenIds", "type": "uint256[]" }],
      "name": "stake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [{ "internalType": "uint256[]", "name": "_tokenIds", "type": "uint256[]" }],
      "name": "unstake",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "claimRewards",
      "outputs": [],
      "stateMutability": "nonpayable",
      "type": "function"
    },
    {
      "inputs": [
        { "internalType": "address", "name": "user", "type": "address" },
        { "internalType": "uint256", "name": "start", "type": "uint256" },
        { "internalType": "uint256", "name": "count", "type": "uint256" }
      ],
      "name": "getUserNFTs",
      "outputs": [{ "internalType": "uint256[]", "name": "", "type": "uint256[]" }],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "RewardToken",
      "outputs": [{ "internalType": "contract IERC20", "name": "", "type": "address" }],
      "stateMutability": "view",
      "type": "function"
    },
  ], []);

  const checkAndSwitchChain = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      alert("No crypto wallet found. Please install MetaMask.");
      return false;
    }

    try {
      const currentChainId = await ethereum.request({ method: 'eth_chainId' });
      if (currentChainId !== shibariumChainId) {
        try {
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: shibariumChainId }],
          });
          setCorrectChain(true);
          return true;
        } catch (switchError) {
          if (switchError.code === 4902) {
            try {
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: shibariumChainId,
                  chainName: "Shibarium Mainnet",
                  nativeCurrency: {
                    name: "SHIB",
                    symbol: "SHIB",
                    decimals: 18,
                  },
                  rpcUrls: ["https://rpc.shibrpc.com"],
                  blockExplorerUrls: ["https://shibariumscan.io"],
                }],
              });
              setCorrectChain(true);
              return true;
            } catch (addError) {
              console.error("Failed to add Shibarium chain to MetaMask:", addError);
              alert("Failed to add Shibarium chain. Please add it manually.");
              return false;
            }
          } else {
            console.error("Error switching to Shibarium chain:", switchError);
            alert("Please switch to the Shibarium chain to continue.");
            return false;
          }
        }
      }
      setCorrectChain(true);
      return true;
    } catch (error) {
      console.error("Error checking chain ID:", error);
      return false;
    }
  };

  useEffect(() => {
    const checkChain = async () => {
      const isOnShibarium = await checkAndSwitchChain();
      if (!isOnShibarium) {
        setCorrectChain(false);
      }
    };

    checkChain();
  }, []);

  useEffect(() => {
    if (correctChain) {
      const fetchUserAddress = async () => {
        if (window.ethereum) {
          const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });
          setUserAddress(accounts[0]);
        }
      };
      fetchUserAddress();
    }
  }, [correctChain]);

  useEffect(() => {
    if (correctChain) {
      const fetchStakeInfo = async () => {
        if (window.ethereum) {
          try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(selectedContract, contractABI, signer);
            const connectedAddress = await signer.getAddress();

            const unstakeNFTResponse = await contract.getUserNFTs(connectedAddress, 0, 10000);
            const unstakeNFT = unstakeNFTResponse.map(id => id.toNumber());

            let totalRewards = 0;

            if (unstakeNFT.length > 0) {
              const rewardsResponse = await contract.getRewardsPerDayMultiple(unstakeNFT);
              const rewards = rewardsResponse.map(reward => parseFloat(ethers.utils.formatUnits(reward, 18)));
              totalRewards = rewards.reduce((total, current) => total + current, 0);
            }

            console.log(`Total rewards per day: ${totalRewards}`);
            setTotalRewards(totalRewards);
          } catch (error) {
            console.error('Error fetching stake info:', error);
            setTotalRewards(0);
          }
        } else {
          console.log('Please install MetaMask or another Ethereum wallet provider.');
        }
      };
      fetchStakeInfo();
    }
  }, [contractABI, selectedContract, correctChain]);

  useEffect(() => {
    if (correctChain) {
      const checkApproval = async () => {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const signer = provider.getSigner();
          const tokenContract = new ethers.Contract(nftAddress, contractABI, signer);
          const userAddress = await signer.getAddress();
          const allowance = await tokenContract.isApprovedForAll(userAddress, selectedContract);
          setIsApproved(allowance);
          console.log(allowance);
        }
      };
      checkApproval();
    }
  }, [userAddress, selectedContract, correctChain]);

  const handleApprove = async () => {
    if (!correctChain) return;

    if (window.ethereum) {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const tokenContract = new ethers.Contract(nftAddress, contractABI, signer);
      const tx = await tokenContract.setApprovalForAll(selectedContract, true);
      await tx.wait();
      setIsApproved(true);
    }
  };

  useEffect(() => {
    if (correctChain) {
      const fetchTokenSymbol = async () => {
        if (window.ethereum) {
          const provider = new ethers.providers.Web3Provider(window.ethereum);
          const contract = new ethers.Contract(selectedContract, contractABI, provider);
          try {
            const rewardTokenAddress = await contract.RewardToken();
            const tokenContract = new ethers.Contract(rewardTokenAddress, [
              {
                "constant": true,
                "inputs": [],
                "name": "symbol",
                "outputs": [{ "name": "", "type": "string" }],
                "type": "function"
              }
            ], provider);
            const symbol = await tokenContract.symbol();
            setTokenSymbol(symbol);
          } catch (error) {
            console.error('Error fetching token symbol:', error);
            setTokenSymbol('');
          }
        }
      };
      fetchTokenSymbol();
    }
  }, [selectedContract, contractABI, correctChain]);

  useEffect(() => {
    if (correctChain) {
      const fetchStakeInfo = async () => {
        if (window.ethereum) {
          try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const contract = new ethers.Contract(selectedContract, contractABI, signer);
            const connectedAddress = await signer.getAddress();

            const rewardsPerTokenPerDay = await contract.rewardsPerTokenPerDay(1);
            const formattedRewardsPerTokenPerDay = ethers.utils.formatUnits(rewardsPerTokenPerDay, 18);

            const stakeInfo = await contract.userStakeInfo(connectedAddress);
            const formattedRewardsPerDay = ethers.utils.formatUnits(stakeInfo.rewardsPerDay, 18);
            const formattedAvailableRewards = ethers.utils.formatUnits(stakeInfo.userAvailableRewards, 18);

            const balance = await contract.balanceOf(connectedAddress);
            const formattedBalance = ethers.utils.formatUnits(balance, 0);

            setRewardsPerDay(formattedRewardsPerDay);
            setStakedAmount(formattedBalance);
            setAvailableRewards(formattedAvailableRewards);
            setRewardsPerTokenPerDay(formattedRewardsPerTokenPerDay);
          } catch (error) {
            console.error('Error fetching stake info:', error);
          }
        } else {
          console.log('Please install MetaMask or another Ethereum wallet provider.');
        }
      };
      const interval = setInterval(fetchStakeInfo, 1000);
      return () => clearInterval(interval);
    }
  }, [contractABI, selectedContract, correctChain]);

  useEffect(() => {
    if (correctChain) {
      const fetchContractTokenBalance = async () => {
        if (window.ethereum) {
          try {
            await window.ethereum.request({ method: 'eth_requestAccounts' });
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            const connectedAddress = await signer.getAddress();

            const erc20ABI = [
              "function balanceOf(address owner) view returns (uint256)"
            ];

            const tokenContract = new ethers.Contract(nftAddress, erc20ABI, provider);
            const balance = await tokenContract.balanceOf(selectedContract);
            const formattedBalance = ethers.utils.formatUnits(balance, 0);

            const balanceWallet = await tokenContract.balanceOf(connectedAddress);
            const formattedBalanceWallet = ethers.utils.formatUnits(balanceWallet, 0);

            setUnStaked(formattedBalanceWallet);
            setTotalStaked(formattedBalance);
          } catch (error) {
            console.error('Error checking contract token balance:', error);
          }
        } else {
          console.log('Please install MetaMask or another Ethereum wallet provider.');
        }
      };
      const intervalId = setInterval(fetchContractTokenBalance, 1000);
      return () => clearInterval(intervalId);
    }
  }, [selectedContract, correctChain]);

  const handleUnStakeNFTs = async () => {
    if (!correctChain || !window.ethereum) {
      alert("Please install MetaMask and switch to the Shibarium chain to use this feature.");
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(selectedContract, contractABI, signer);
      const transaction = await stakingContract.unstake(selectedNfts);
      await transaction.wait();

      setSelectedNfts([]);
      setShowUnstakeModal(false);
      showPopup('NFTs unstaked successfully! ', transaction.hash);
    } catch (error) {
      console.error("Unstaking failed:", error);
      alert("Unstaking failed. See console for details.");
    }
  };

  const showPopup = (message, txHash = '') => {
    const txLink = txHash ? `https://www.shibariumscan.io/tx/${txHash}` : '';
    setPopup({ visible: true, message, txLink });
    setTimeout(() => {
      setPopup({ visible: false, message: '', txLink: '' });
    }, 10000);
  };

  const handleStakeNFTs = async () => {
    if (!correctChain || !window.ethereum) {
      alert("Please install MetaMask and switch to the Shibarium chain to use this feature.");
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(selectedContract, contractABI, signer);
      const transaction = await stakingContract.stake(selectedNfts);
      await transaction.wait();

      setSelectedNfts([]);
      setShowNftModal(false);
      showPopup('NFTs staked successfully! ', transaction.hash);
    } catch (error) {
      console.error("Staking failed:", error);
      alert("Staking failed. See console for details.");
    }
  };

  const handleClaimAll = async () => {
    if (!correctChain || !window.ethereum) {
      alert("Please install MetaMask and switch to the Shibarium chain to use this feature.");
      return;
    }

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const stakingContract = new ethers.Contract(selectedContract, contractABI, signer);
      const transaction = await stakingContract.claimRewards();
      await transaction.wait();

      showPopup('Claim is successful! ', transaction.hash);
    } catch (error) {
      console.error("Claiming of rewards failed:", error);
      alert("Claiming of rewards failed. See console for details.");
    }
  };

  const handleOpenUnstakeModal = async () => {
    setShowUnstakeModal(true);
  };

  const togglePopup = (visible, message = '', txLink = '') => {
    setPopup({ visible, message, txLink });
  };

  const handleOpenNftModal = () => {
    setShowNftModal(true);
  };

  const handleCloseNftModal = () => {
    setSelectedNfts([]);
    setShowNftModal(false);
    setShowUnstakeModal(false);
  };

  const handleSelectNft = (nftId) => {
    setSelectedNfts(prev => [...prev, nftId]);
  };

  const handleDeselectNft = (nftId) => {
    setSelectedNfts(prev => prev.filter(id => id !== nftId));
  };

  const onSelect100 = () => {
    setSelectedNfts(allNfts.slice(0, 100).map(nft => nft.id));
  };

  const handleNftsLoaded = (nfts) => {
    setAllNfts(nfts);
  };

  const getNFTBalance = async (walletAddress) => {
    const provider = new ethers.providers.Web3Provider(window.ethereum);
    await provider.send('eth_requestAccounts', []);
    const nftContract = new ethers.Contract(nftAddress, [
      {
        "constant": true,
        "inputs": [{ "name": "_owner", "type": "address" }],
        "name": "balanceOf",
        "outputs": [{ "name": "", "type": "uint256" }],
        "type": "function"
      }
    ], provider);

    try {
      const balance = await nftContract.balanceOf(walletAddress);
      setNftBalance(balance.toString());
      console.log(`Balance: ${balance.toString()}`);
      return balance;
    } catch (error) {
      console.error('Error fetching NFT balance:', error);
      setNftBalance(0);
    }
  };

  getNFTBalance(selectedContract);

  const handleDeselectAll = () => {
    setSelectedNfts([]);
  };

  const handleContractChange = (event) => {
    setSelectedContract(event.target.value);
  };

  return (
    <div className="staking-container">
      <div className="staking-stats-header">[SHIBARIUM CHAIN] MY STAKING STATS (HOUNDS)</div>
      <div className="status-indicators">
        <div className="indicator">
          <img src="/Icon1.png" alt="Staked" />
          <span><b>STAKED:</b> {stakedAmount}</span>
        </div>
        <div className="indicator">
          <img src="/Icon2.png" alt="Rewards" />
          <span><b>RATE:</b> {rewardsPerDay} {tokenSymbol} / Day </span>
        </div>
        <div className="indicator">
          <img src="/Icon3.png" alt="Balance" />
          <span><b>REWARD:</b> {Number(walkBalance).toFixed(2)} {tokenSymbol} </span>
        </div>
        <button className="indicator claim-all" onClick={handleClaimAll}>
          <img src="/Icon4.png" alt="Claim" />
          CLAIM REWARD
        </button>
      </div>

      <div className="cards">
        <div className="card stake">
          <div className="card-content">
            <h2>STAKE</h2>
            <p>Hounds Available For Staking: {unStaked} <br />({totalRewards} ${tokenSymbol}/Day)</p>
            <div className="images-with-text">
              <div className="image-and-text">
                <img src="Icon6.png" alt="Description" />
              </div>
            </div>
            {
              !isApproved ? (
                <button onClick={handleApprove}>Approve</button>
              ) : (
                unStaked > 0 ? (
                  <>
                    <button className="large-font-button" onClick={handleOpenNftModal}>Select NFT(s) to Stake</button>
                  </>
                ) : (
                  <p className="error-message">You have no NFT available to stake.</p>
                )
              )
            }
          </div>
        </div>

        <div className="card unstake">
          <div className="card-content">
            <h2>UNSTAKE</h2>
            <p>Hound(s) Staked: {stakedAmount} <br />(Total: {nftBalance} / 10000 Staked)</p>
            <div className="images-with-text">
              <div className="image-and-text">
                <img src="Icon5.png" alt="Description" />
              </div>
            </div>
            {
              stakedAmount > 0 ? (
                <>
                  <button className="large-font-button" onClick={handleOpenUnstakeModal}>Select NFT(s) to Unstake</button>
                </>
              ) : (
                <p className="error-message">You have no NFT available to unstake.</p>
              )
            }
          </div>
        </div>
      </div>
      {showNftModal && (
        <NFTSelectionModal
          userAddress={userAddress}
          selectedNfts={selectedNfts}
          onSelectNft={handleSelectNft}
          onDeselectNft={handleDeselectNft}
          onClose={handleCloseNftModal}
          onDeselectAll={handleDeselectAll}
          onStake={handleStakeNFTs}
          onSelect100={onSelect100}
          onNftsLoaded={handleNftsLoaded}
          selectedContract={selectedContract}
        />
      )}

      {showUnstakeModal && (
        <NFTUnstakeModal
          userAddress={userAddress}
          selectedNfts={selectedNfts}
          onSelectNft={handleSelectNft}
          onDeselectNft={handleDeselectNft}
          onClose={handleCloseNftModal}
          onDeselectAll={handleDeselectAll}
          onStake={handleUnStakeNFTs}
          onSelect100={onSelect100}
          onNftsLoaded={handleNftsLoaded}
          selectedContract={selectedContract}
        />
      )}

      {popup.visible && (
        <div className="mint-popup">
          <span>{popup.message}</span>
          {popup.txLink && <a href={popup.txLink} target="_blank" rel="noopener noreferrer">View Transaction</a>}
          <button className="mint-popup-close" onClick={() => togglePopup(false)}>&times;</button>
        </div>
      )}
    </div>
  );
};

export default Stake;
