import React, { useState, useEffect, useMemo } from 'react';
import { ethers } from 'ethers';
import './Mint.css';

const Mint = () => {
  // State variables
  const [totalMinted, setTotalMinted] = useState(0);
  const [cost, setCost] = useState(0);
  const [maxMintAmount, setMaxMintAmount] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [numToMint, setNumToMint] = useState(1);
  const [popup, setPopup] = useState({ visible: false, message: '', txLink: '' });
  const [walletBalance, setWalletBalance] = useState('');

  // Contract details
  const contractAddress = "0x4aE74974c3c4A403314f8597f471A6DfCe4e51c4";
  const shibariumChainId = '0x6d'; // Shibarium chain ID in hexadecimal
  const contractABI = useMemo(() => [
    {
      "inputs": [],
      "name": "price",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "_maxSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "_maxBatchSize",
      "outputs": [
        {
          "internalType": "uint16",
          "name": "",
          "type": "uint16"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "uint256",
          "name": "batchSize",
          "type": "uint256"
        }
      ],
      "name": "publicMintToSender",
      "outputs": [],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [
        {
          "internalType": "address",
          "name": "_to",
          "type": "address"
        },
        {
          "internalType": "uint256",
          "name": "_mintAmount",
          "type": "uint256"
        }
      ],
      "name": "mint",
      "outputs": [
        {
          "internalType": "uint256[]",
          "name": "ids",
          "type": "uint256[]"
        }
      ],
      "stateMutability": "payable",
      "type": "function"
    },
    {
      "inputs": [],
      "name": "totalSupply",
      "outputs": [
        {
          "internalType": "uint256",
          "name": "",
          "type": "uint256"
        }
      ],
      "stateMutability": "view",
      "type": "function"
    },
  ], []);

  // Function to fetch total minted tokens
  const fetchTotalMinted = async (contract) => {
    const totalMinted = await contract.totalSupply();
    setTotalMinted(totalMinted.toNumber());
  };

  // Function to fetch contract data
  useEffect(() => {
    const fetchData = async () => {
      const provider = new ethers.providers.JsonRpcProvider('https://rpc.shibrpc.com');
      const contract = new ethers.Contract(contractAddress, contractABI, provider);

      try {
        const [costBNB, maxSupply, maxMint] = await Promise.all([
          contract.price(),
          contract._maxSupply(),
          contract._maxBatchSize(),
        ]);

        setCost(ethers.utils.formatEther(costBNB));
        setMaxMintAmount(maxMint.toNumber());
        setTotalSupply(maxSupply.toNumber());
        fetchTotalMinted(contract);
      } catch (error) {
        console.error('Failed to fetch data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 1000);

    return () => clearInterval(interval);
  }, [contractABI]);

  const showPopup = (message, txHash = '') => {
    const txLink = txHash ? `https://www.shibariumscan.io/tx/${txHash}` : '';
    setPopup({ visible: true, message, txLink });

    setTimeout(() => {
      setPopup({ visible: false, message: '', txLink: '' });
    }, 10000);
  };

  const checkAndSwitchChain = async () => {
    const { ethereum } = window;
    if (!ethereum) {
      alert("No crypto wallet found. Please install MetaMask.");
      return false;
    }
  
    try {
      // Get the current chain ID
      const currentChainId = await ethereum.request({ method: 'eth_chainId' });
  
      // Check if the current chain ID matches the desired Shibarium chain ID
      if (currentChainId !== shibariumChainId) {
        try {
          // Attempt to switch to the Shibarium chain
          await ethereum.request({
            method: 'wallet_switchEthereumChain',
            params: [{ chainId: shibariumChainId }],
          });
          return true; // Chain switch successful
        } catch (switchError) {
          // This error code indicates the chain has not been added to MetaMask
          if (switchError.code === 4902) {
            try {
              // Attempt to add the Shibarium chain to MetaMask
              await ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [{
                  chainId: shibariumChainId,
                  chainName: "Shibarium Mainnet",
                  nativeCurrency: {
                    name: "SHIB",
                    symbol: "SHIB",
                    decimals: 18,
                  },
                  rpcUrls: ["https://rpc.shibrpc.com"],
                  blockExplorerUrls: ["https://shibariumscan.io"],
                }],
              });
              return true; // Chain added and switched successfully
            } catch (addError) {
              console.error("Failed to add Shibarium chain to MetaMask:", addError);
              alert("Failed to add Shibarium chain. Please add it manually.");
              return false; // Failed to add the chain
            }
          } else {
            console.error("Error switching to Shibarium chain:", switchError);
            alert("Please switch to the Shibarium chain to continue.");
            return false; // User rejected the switch or an error occurred
          }
        }
      }
      return true; // Already on the correct chain
    } catch (error) {
      console.error("Error checking chain ID:", error);
      return false; // Error in retrieving chain ID
    }
  };
  

  
  const handleMint = async () => {
    if (!await checkAndSwitchChain()) return;

    try {
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      await provider.send("eth_requestAccounts", []);
      const signer = provider.getSigner();
      const contract = new ethers.Contract(contractAddress, contractABI, signer);

      const totalCost = parseFloat(cost) * numToMint;
      const totalCostWei = ethers.utils.parseEther(totalCost.toFixed(18));

      const transaction = await contract.publicMintToSender(numToMint, { value: totalCostWei });
      await transaction.wait();
      showPopup('Mint successful!', transaction.hash);
    } catch (error) {
      console.error("Minting failed:", error);
      showPopup('Minting failed.');
    }
  };

  const togglePopup = (visible, message = '', txLink = '') => {
    setPopup({ visible, message, txLink });
  };

  return (
    <div className="mint-container">
      <div className="mint-content">
        <div className="mint-title">Mint Your Hounds</div>
        <img src="/34.png" alt="NFT Sample" className="mint-image" />
        <div className="mint-info">
          <p><label>Cost per token:</label> {cost} Bone</p>
          <p><label>Max mint amount:</label> {maxMintAmount}</p>
          <p><label>Total supply:</label> {totalSupply}</p>
          <p><label>Total minted:</label> {totalMinted}</p>
        </div>
        <div className="mint-action">
          <input
            type="number"
            value={numToMint}
            onChange={(e) => setNumToMint(Number(e.target.value))}
            min="1"
            max={maxMintAmount}
          />
          <button onClick={handleMint}>Mint</button>
        </div>
      </div>
      {popup.visible && (
        <div className="mint-popup">
          <span>{popup.message}</span>
          {popup.txLink && <a href={popup.txLink} target="_blank" rel="noopener noreferrer">View Transaction</a>}
          <button className="mint-popup-close" onClick={() => togglePopup(false)}>&times;</button>
        </div>
      )}
    </div>
  );
};

export default Mint;
